import styled from 'styled-components';
import { classic } from '~/themes';

type IProps = {
  color?: 'primary';
  value: number;
};

function ProgressSm({ color, value }: IProps) {
  return (
    <Container color={color}>
      <SProgress color={color} width={value}></SProgress>
      {value > 0 ? <p>{value}%</p> : <p>0%</p>}
    </Container>
  );
}

export default ProgressSm;

const Container = styled.div<{
  color?: 'primary';
}>`
  align-items: center;
  border: 1px solid #fff;
  background-color: #ffffff4d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  overflow: hidden;
  position: relative;
  height: 20px;
  min-width: 98px;
  width: 100%;

  & > p {
    color: #fff;
    font-size: 0.9rem;
    font-family: 'MaliBold', sans-serif;
    z-index: 2;
  }
`;

const SProgress = styled.div<{
  width?: number;
  color?: 'primary';
}>`
  align-items: center;
  background-color: ${({ color }) =>
    color ? classic.token?.colorPrimary : '#ffffff'};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${({ width }) => `${width}%`};
`;
