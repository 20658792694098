/* eslint-disable */

export const AllTypesProps: Record<string,any> = {
	DateTime: `scalar.DateTime` as const,
	ClothingType: "enum" as const,
	ClothingRarity: "enum" as const,
	Location: "enum" as const,
	Query:{
		getRole:{

		},
		getPermission:{

		},
		getConfig:{

		},
		getMail:{

		},
		login:{

		},
		register:{

		},
		getUser:{

		},
		getAvatar:{

		},
		getAllSuccessUnlock:{

		},
		getAllSuccess:{

		},
		getTracking:{

		},
		getReputation:{

		},
		getArcsProgress:{

		},
		getClothingStats:{

		}
	},
	EError: "enum" as const,
	Mutation:{
		updateAvatar:{

		},
		resetAllSuccess:{

		},
		incrementCountSuccess:{

		},
		updateCountSuccess:{

		},
		unLockSuccess:{

		},
		setScore:{

		},
		updateTracking:{

		},
		popup:{

		}
	}
}

export const ReturnTypes: Record<string,any> = {
	Health:{
		env:"String",
		time:"String",
		status:"String"
	},
	Permissions:{
		id:"Float",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		roles:"Roles"
	},
	DateTime: `scalar.DateTime` as const,
	ClothingUnlock:{
		total:"Int",
		normal:"Int",
		rare:"Int",
		epic:"Int",
		legendary:"Int"
	},
	Clothing:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		description:"String",
		image:"String",
		type:"ClothingType",
		rarity:"ClothingRarity",
		isDefault:"Boolean",
		avatarEntityHat:"Avatar",
		avatarEntityTop:"Avatar",
		avatarEntityBottom:"Avatar"
	},
	Avatar:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		face:"String",
		color:"String",
		hat:"Clothing",
		hatId:"String",
		top:"Clothing",
		topId:"String",
		bottom:"Clothing",
		bottomId:"String",
		users:"Users"
	},
	Progress:{
		progress:"Float"
	},
	ArcProgress:{
		arc1:"Progress",
		arc2:"Progress",
		arc3:"Progress",
		arc4:"Progress",
		arc5:"Progress",
		arc6:"Progress",
		arc7:"Progress",
		arc8:"Progress"
	},
	Tracking:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		idQuest:"String",
		idMainQuest:"String",
		queteAnnexe:"Boolean",
		location:"Location",
		users:"Users"
	},
	TrackingProgress:{
		arcProgress:"ArcProgress",
		totalProgress:"Float",
		queteAnnexe:"Boolean"
	},
	ReputationProgress:{
		canopee:"Float",
		buisnessDistrict:"Float",
		lagon:"Float",
		residentialDistrict:"Float",
		hub:"Float"
	},
	SuccessList:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		description:"String",
		image:"String",
		code:"String",
		Success:"Success"
	},
	Success:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		users:"Users",
		successList:"SuccessList",
		successListId:"Float"
	},
	GetAllSuccess:{
		name:"String",
		description:"String",
		image:"String",
		code:"String",
		count:"Int",
		unlock:"DateTime"
	},
	Users:{
		id:"Int",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		email:"String",
		password:"String",
		refreshToken:"String",
		firstName:"String",
		lastName:"String",
		azureAdOpenId:"String",
		role:"Roles",
		roleId:"Int",
		avatar:"Avatar",
		avatarId:"Int",
		tracking:"Tracking",
		trackingId:"Int",
		successUnlock:"Success"
	},
	Roles:{
		id:"Float",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		name:"String",
		permissions:"Permissions",
		users:"Users"
	},
	Configs:{
		id:"Float",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		key:"String",
		value:"String"
	},
	Mail:{
		id:"Float",
		uuid:"String",
		createdAt:"DateTime",
		updatedAt:"DateTime",
		key:"String",
		to:"String",
		data:"String"
	},
	Query:{
		getHealthGql:"Health",
		getRole:"Roles",
		getRoles:"Roles",
		getPermission:"Permissions",
		getPermissions:"Permissions",
		getConfig:"Configs",
		getConfigs:"Configs",
		getMail:"Mail",
		getMails:"Mail",
		sendMail:"Boolean",
		login:"AuthType",
		register:"AuthType",
		refresh:"AuthType",
		logout:"Boolean",
		getUser:"Users",
		getUsers:"Users",
		getAvatar:"Avatar",
		getAllSuccessUnlock:"Success",
		getAllSuccess:"GetAllSuccess",
		getTracking:"Tracking",
		getReputation:"ReputationProgress",
		getArcsProgress:"TrackingProgress",
		getClothingStats:"ClothingUnlock"
	},
	AuthType:{
		"...on Tokens":"Tokens",
		"...on CustomError":"CustomError"
	},
	Tokens:{
		access_token:"String",
		refresh_token:"String"
	},
	CustomError:{
		error:"EError",
		message:"String"
	},
	Mutation:{
		updateAvatar:"Boolean",
		resetAllSuccess:"Boolean",
		incrementCountSuccess:"Boolean",
		updateCountSuccess:"Boolean",
		unLockSuccess:"Boolean",
		setScore:"Boolean",
		updateTracking:"Boolean",
		popup:"Boolean"
	}
}

export const Ops = {
query: "Query" as const,
	mutation: "Mutation" as const
}