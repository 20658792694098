import { ThemeConfig } from 'antd';
// https://ant.design/docs/react/customize-theme
export const classic: ThemeConfig = {
  token: {
    colorPrimary: '#e75d5d',
    colorFillSecondary: '#00b9ff',
    colorText: '#270c21',
    colorTextSecondary: '#ffffff',
  },
  components: {
    Input: {
      colorText: '#000000',
      colorTextPlaceholder: '#918f8a',
    },
    Modal: {
      colorText: '#fff',
    },
    Drawer: {
      colorText: '#000000',
    },
    Table: {
      colorText: '#000000',
    },
  },
};
