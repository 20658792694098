import { Selector, InputType, GraphQLTypes } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

export const GQL_SUCCESS = ({ email }: { email: string }) =>
  typedGql('query')({
    getAllSuccess: [
      {
        email: email,
      },
      successSelector,
    ],
  });

const successSelector = Selector('GetAllSuccess')({
  name: true,
  description: true,
  image: true,
  code: true,
  unlock: true,
});

export type StoredSuccess = InputType<
  GraphQLTypes['GetAllSuccess'],
  typeof successSelector
>;
