import React from 'react';
import styled from 'styled-components';
import { classic } from '~/themes';

type IProps = {
  textcolor?: 'primary' | 'text';
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  type?: 'button' | 'submit' | 'reset';
  stylebold?: boolean;
  textTransform?: 'lowercase' | 'uppercase';
  textUnderline?: boolean;
  textSize?: string;
  withhover?: boolean;
};

function ButtonSm({
  textcolor,
  onClick,
  children,
  disabled,
  hidden,
  type,
  stylebold,
  textTransform,
  textUnderline,
  textSize,
  withhover,
}: IProps) {
  return (
    <Container
      disabled={disabled?.toString()}
      hidden={hidden?.toString()}
      onClick={onClick}
      $stylebold={stylebold?.toString()}
      $textcolor={textcolor}
      textSize={textSize}
      $texttransform={textTransform}
      textUnderline={textUnderline?.toString()}
      type={type}
      $withhover={withhover?.toString()}
    >
      {children}
    </Container>
  );
}

export default ButtonSm;

const Container = styled.button<{
  disabled?: string;
  hidden?: string;
  $textcolor?: 'primary' | 'text';
  $stylebold?: string;
  $texttransform?: 'lowercase' | 'uppercase';
  textSize?: string;
  textUnderline?: string;
  $withhover?: string;
}>`
  align-items: center;
  background-color: transparent;
  color: ${({ $textcolor }) => {
    if ($textcolor === 'primary') return classic.token?.colorPrimary;
    if ($textcolor === 'text') return classic.token?.colorPrimary;

    return '#fff';
  }};
  display: flex;
  flex-direction: row;
  font-family: ${({ $stylebold }) =>
    $stylebold ? `'MaliBold', sans-serif` : `'MaliRegular', sans-serif`};
  font-size: ${({ textSize }) => (textSize ? textSize : '0.9rem')};
  justify-content: center;
  letter-spacing: normal;
  opacity: ${({ disabled, hidden }) => {
    if (hidden) return 0;
    if (disabled) return 0.7;

    return 1;
  }};
  padding: 15px 0 3px 0;
  margin: 0 15px 12px 15px;
  pointer-events: ${({ disabled, hidden }) =>
    disabled ?? hidden ? 'none' : 'auto'};
  position: relative;
  text-transform: ${({ $texttransform }) =>
    $texttransform ? $texttransform : 'none'};
  z-index: 10;
  &:hover {
    text-decoration: ${({ $withhover }) => ($withhover ? 'underline' : 'none')};
  }
  &::before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    border-bottom: ${({ textUnderline, $textcolor }) => {
      if (textUnderline) {
        if ($textcolor === 'primary')
          return `1px solid ${classic.token?.colorPrimary}`;
        if ($textcolor === 'text')
          return `1px solid ${classic.token?.colorPrimary}`;

        return `1px solid ${classic.token?.colorTextSecondary}`;
      }

      return 'none';
    }};
  }
  @media screen and (max-width: 767px) {
    padding: 10px 0 3px 0;
  }
  & > a {
    color: ${({ $textcolor }) => {
      if ($textcolor === 'primary') return classic.token?.colorPrimary;
      if ($textcolor === 'text') return classic.token?.colorPrimary;

      return classic.token?.colorPrimary;
    }};
    font-family: ${({ $stylebold }) =>
      $stylebold ? `'MaliBold', sans-serif` : `'MaliRegular', sans-serif`};
    font-size: ${({ textSize }) => (textSize ? textSize : '0.9rem')};
  }
  @media screen and (max-width: 667px) {
    margin: 0 10px 12px 10px;
  }
`;
