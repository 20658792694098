import React from 'react';
import styled from 'styled-components';

import bgButton from '@assets/image/background/bg-button.png';
import { classic } from '~/themes';

type IProps = {
  textcolor?: 'primary' | 'secondary';
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  type?: 'button' | 'submit' | 'reset';
  isActive?: boolean;
};

function ButtonLink({
  textcolor,
  isActive,
  onClick,
  children,
  disabled,
  hidden,
  type,
}: IProps) {
  return (
    <Container
      disabled={disabled?.toString()}
      hidden={hidden?.toString()}
      isActive={isActive?.toString()}
      onClick={onClick}
      $textcolor={textcolor}
      type={type}
    >
      {children}
    </Container>
  );
}

export default ButtonLink;

const Container = styled.button<{
  disabled?: string;
  hidden?: string;
  $textcolor?: 'primary' | 'secondary';
  isActive?: string;
}>`
  align-items: center;
  background: ${({ isActive }) =>
    isActive ? `url(${bgButton}) center / 100% 100% no-repeat` : 'transparent'};
  color: ${({ $textcolor }) =>
    $textcolor ? classic.token?.colorPrimary : '#ffffff'};
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-family: 'MaliBold', sans-serif;
  justify-content: center;
  letter-spacing: normal;
  opacity: ${({ disabled, hidden }) => {
    if (hidden) return 0;
    if (disabled) return 0.7;

    return 1;
  }};
  padding: 20px;
  pointer-events: ${({ disabled, hidden }) =>
    disabled ?? hidden ? 'none' : 'auto'};
  &:hover {
    background: url(${bgButton}) center / 100% 100% no-repeat;
  }
  @media screen and (max-width: 667px) {
    padding: 10px 15px;
  }
  @media screen and (max-width: 667px) {
    font-size: 0.9rem;
  }
  @media screen and (max-width: 415px) {
    font-size: 0.85rem;
  }
`;
