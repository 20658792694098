import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLingui } from '@lingui/react';
import Title from '~/utils/components/ui/Title';
import bgAren from '@assets/image/background/bg-aren.png';
import imgParallaxTop from '@assets/image/background/paralax-top-1.png';
import { SDivFlexColumn, SParallaxSpacer, SSpacer } from '~/utils/styles';
import { useAuth } from '~/helpers/store/auth';
import { IDistrict } from '~/pages/dashboard/types';
import Progress from '~/utils/components/ui/Progress';
import { StoredReputation } from '~/gql/tracking/tracking';

type IProps = {
  reputation: StoredReputation | null;
};

function Reputation({ reputation }: IProps) {
  const { i18n } = useLingui();
  const [agentIOs, setAgentIOs] = useState<boolean>(false);
  const { payload } = useAuth();
  const [districtArr, setDistrictArr] = useState<IDistrict[]>([]);

  useEffect(() => {
    function getProgressByMap() {
      const _districtArr: IDistrict[] = [
        {
          name: i18n._('common.district.canopee'),
          value: reputation?.canopee ?? 0,
        },
        {
          name: i18n._('common.district.business'),
          value: reputation?.buisnessDistrict ?? 0,
        },
        {
          name: i18n._('common.district.lagon'),
          value: reputation?.lagon ?? 0,
        },
        {
          name: i18n._('common.district.residentiel'),
          value: reputation?.residentialDistrict ?? 0,
        },
        {
          name: i18n._('common.district.hub'),
          value: reputation?.hub ?? 0,
        },
      ];
      setDistrictArr(_districtArr);
    }
    getProgressByMap();
  }, [reputation]);

  function getColorForReputation(
    ind: number
  ): 'green' | 'yellow' | 'blue' | 'pink' | 'violet' | undefined {
    if (ind === 0) return 'green';
    if (ind === 1) return 'yellow';
    if (ind === 2) return 'blue';
    if (ind === 3) return 'pink';

    return 'violet';
  }
  useEffect(() => {
    const _agent = window.navigator.userAgent.includes('iPhone');
    setAgentIOs(_agent);
  }, []);

  if (!payload) return null;

  return (
    <Container id="reputation" $agentios={agentIOs.toString()}>
      <SImgTop alt="" src={imgParallaxTop} />
      <SParallaxSpacer />
      <Title withbackground text={i18n._('menu.reputation')} />
      <SDivFlexColumn>
        {districtArr.map((item, index) => (
          <Progress
            color={getColorForReputation(index)}
            district={item}
            key={index + item.name}
          />
        ))}
      </SDivFlexColumn>
      <SSpacer />
      <SParallaxSpacer />
    </Container>
  );
}

export default Reputation;

const Container = styled.div<{
  $agentios?: string;
}>`
  align-items: center;
  background: ${({ $agentios }) =>
    $agentios
      ? `url(${bgAren}) top/cover no-repeat`
      : `url(${bgAren}) top/cover no-repeat fixed`};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  position: relative;
  width: 100%;
  position: relative;
  transform-style: preserve-3d;
  transform: translate3d(0, 0, 0);
`;

const SImgTop = styled.img`
  margin-top: -1px;
  width: 100%;
`;
