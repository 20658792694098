import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLingui } from '@lingui/react';

import Title from '~/utils/components/ui/Title';
import ImgIconLg from '~/utils/components/ui/ImgIconLg';
import { SDivListItems } from '~/utils/styles';
import qAide from '@assets/image/icons/quest/q-aide.png';
import qBallonFoot from '@assets/image/icons/quest/q-ballon-foot.png';
import qChut from '@assets/image/icons/quest/q-chut.png';
import qCv from '@assets/image/icons/quest/q-cv.png';
import qEcran from '@assets/image/icons/quest/q-ecran.png';
import qHobo from '@assets/image/icons/quest/q-hobo.png';
import qMalette from '@assets/image/icons/quest/q-malette.png';
import qPoint from '@assets/image/icons/quest/q-poignee-main.png';
import { Tooltip } from 'antd';
import { IQuestItem } from '~/pages/dashboard/types';
import { byField } from '~/pages/dashboard/utils';
import { useAuth } from '~/helpers/store/auth';

type IProps = {
  questsBool: boolean[];
};

type Quest = {
  uuid: string;
  unityId: string;
  arc: number;
  order: null;
  isMain: false;
  mapBehavior: 'INHERIT';
  name: string;
  description: string;
  status: null;
  progress: boolean;
};

function Quests({ questsBool }: IProps) {
  const { i18n } = useLingui();
  const [questsArr, setQuestsArr] = useState<IQuestItem[]>([]);
  const [quests, setQuests] = useState<Quest[]>([]);
  const { payload } = useAuth();

  useEffect(() => {
    setQuests([
      {
        uuid: 'c887c3a4-f296-4554-95a7-8778dc7f670c',
        unityId: 'AQ1',
        arc: 1,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: 'Droit au but',
        description:
          'Altercation foot, désaccord, dispute, mediateur, bienveillance, comportement',
        status: null,
        progress: questsBool[0],
      },
      {
        uuid: '35dcfb86-afc4-46eb-adf7-22bb7edd4ea1',
        unityId: 'AQ2',
        arc: 2,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: 'Plan de Carrière',
        description:
          "Technique de recherche d'emploi, objectifs, formateur, methodologie",
        status: null,
        progress: questsBool[1],
      },

      {
        uuid: '433fd417-ce83-455e-b240-7f0773939743',
        unityId: 'AQ3',
        arc: 3,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: 'Coup de pouce',
        description:
          'aide, stagiaire, relais, acquis, competences, métier, experience, camarade',
        status: null,
        progress: questsBool[2],
      },
      {
        uuid: '8b46a47d-7a5f-4338-aff8-636b7e2cd9e9',
        unityId: 'AQ4',
        arc: 4,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: "Le meilleur ami de l'homme",
        description: 'Refuge/chiens/bénévolat/association/culture',
        status: null,
        progress: questsBool[3],
      },
      {
        uuid: '9428baf5-f138-42a4-bfa4-e14d00efbdfc',
        unityId: 'AQ5',
        arc: 5,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: 'Infos ou intox ?',
        description:
          'Affiches sauvages, fake news, collectif, citoyenneté, respect des regles en ville',
        status: null,
        progress: questsBool[4],
      },
      {
        uuid: 'ee175341-8c37-40e4-8928-7c0af561571b',
        unityId: 'AQ6',
        arc: 6,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: 'Ça part de là',
        description: 'Prendre contact, annonce, métier, RH, postuler',
        status: null,
        progress: questsBool[5],
      },
      {
        uuid: 'a88eb759-0dd0-4647-a3cb-3f3e66e76e8f',
        unityId: 'AQ7',
        arc: 7,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: "Une longueur d'avance",
        description:
          "S'informer, rester informé, veille, compétences, besoins objectifs, recherche, informations",
        status: null,
        progress: questsBool[6],
      },
      {
        uuid: '8013bbb0-97fd-4b4e-84ff-7a080e07b966',
        unityId: 'AQ8',
        arc: 8,
        order: null,
        isMain: false,
        mapBehavior: 'INHERIT',
        name: "De l'autre côté de l'écran",
        description:
          "skate parc, réseaux sociaux, regles, diffusion, respect, droits d'auteurs, droit à l'image, commentaires",
        status: null,
        progress: questsBool[7],
      },
    ]);
  }, [questsBool]);

  useEffect(() => {
    function getProgressByMap() {
      const _questsArr: IQuestItem[] = quests.map((quest) => {
        return {
          image: getQuestImage(quest.progress, quest.arc),
          name: quest.name,
          description: quest.description,
          arc: quest.arc,
          progress: quest.progress,
        };
      });

      _questsArr.sort(byField('arc'));
      setQuestsArr(_questsArr);
    }

    getProgressByMap();
  }, [quests]);

  function getQuestImage(progress: boolean, arc: number): string | undefined {
    if (!progress) return undefined;
    if (arc === 1) return qBallonFoot;
    if (arc === 2) return qMalette;
    if (arc === 3) return qAide;
    if (arc === 4) return qHobo;
    if (arc === 5) return qChut;
    if (arc === 6) return qPoint;
    if (arc === 7) return qCv;
    if (arc === 8) return qEcran;
    return undefined;
  }

  if (!payload) return null;

  return (
    <Container id="quests">
      <Title withbackground text={i18n._('menu.quests')} />
      <SDivListItems>
        {questsArr.map((item, index) => (
          <Tooltip placement="bottom" key={index} title={item.name}>
            <div>
              <ImgIconLg bg={item.image} />
            </div>
          </Tooltip>
        ))}
      </SDivListItems>
    </Container>
  );
}

export default Quests;

const Container = styled.div`
  align-items: center;
  background: #0f030c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 35px 40px 60px 40px;
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
  @media screen and (max-width: 280px) {
    padding: 35px 20px 60px 20px;
  }
`;
