import { FundOutlined, LogoutOutlined } from '@ant-design/icons';
import { Layout, Menu } from 'antd';
import { useLingui } from '@lingui/react';
import { Link, useLocation } from 'react-router-dom';
import { useEffect, useState } from 'react';

const { Sider } = Layout;

function NavBar() {
  const { i18n } = useLingui();
  const [value, setValue] = useState<string>('');

  const location = useLocation();
  const { pathname } = location;

  useEffect(() => {
    setValue(pathname.substring(1));
  }, [pathname]);

  if (value.length <= 0) {
    return <></>;
  }

  const menuItems = [
    {
      key: 'dashboard',
      icon: <FundOutlined />,
      label: (
        <div>
          {i18n._('common.dashboard')}
          <Link to="/dashboard" />
        </div>
      ),
    },
    {
      key: 'logout',
      icon: <LogoutOutlined />,
      label: (
        <div>
          {i18n._('common.logout')}
          <Link to="/logout" />
        </div>
      ),
    },
  ];

  return (
    <Sider breakpoint="lg">
      <div className="demo-logo-vertical" />
      <Menu
        theme="dark"
        mode="inline"
        style={{ paddingTop: '50px' }}
        defaultSelectedKeys={[value]}
        items={menuItems}
      />
    </Sider>
  );
}

export default NavBar;
