import { useEffect } from 'react';
import { useLingui } from '@lingui/react';
import './Login.css';

function Denied() {
  const { i18n } = useLingui();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = '/';
    }, 3000);
  }, []);

  return (
    <>
      <p style={{ color: 'white' }}>{i18n._('access.denied.message')}</p>
    </>
  );
}

export default Denied;
