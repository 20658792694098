import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  from,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GQL_REFRESH } from '~/gql/auth/auth';

import { env } from './env';
import { Payload, useAuth } from '~/helpers/store/auth';

import { onError } from '@apollo/client/link/error';
import { parseJwt } from '~/utils/jwt';

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        switch (err.extensions.code) {
          // Apollo Server sets code to UNAUTHENTICATED
          // when an AuthenticationError is thrown in a resolver
          case 'UNAUTHENTICATED': {
            refreshClient
              .query({
                query: GQL_REFRESH,
                fetchPolicy: 'no-cache',
              })
              .then((data) => {
                if (data.data.refresh.access_token !== null) {
                  const dataJwt = parseJwt(data.data.refresh.access_token);
                  if (!dataJwt) return forward(operation);

                  const payload: Payload = {
                    access_token: data.data.refresh.access_token,
                    refresh_token: data.data.refresh.refresh_token,
                    ...dataJwt,
                  };
                  useAuth.getState().setPayload(payload);
                }
                return forward(operation);
              })
              .catch((e) => {
                console.log(e);
              });

            return forward(operation);
          }
        }
      }
    }

    // To retry on network errors, we recommend the RetryLink
    // instead of the onError link. This just logs the error.
    if (networkError) {
      console.log(`[Network error]: ${networkError}`);
    }
  }
);

const httpLink = createHttpLink({
  uri: `${env.api.url}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const logged = useAuth.getState().imLoggedAndValid();
  const payload = useAuth.getState().payload;
  if (!logged || !payload) return headers;

  return {
    headers: {
      ...headers,
      authorization: payload.access_token
        ? `Bearer ${payload.access_token}`
        : '',
    },
  };
});

const refreshLink = setContext((_, { headers }) => {
  const logged = useAuth.getState().imLoggedAndValid();
  const payload = useAuth.getState().payload;
  if (!logged || !payload) return headers;

  return {
    headers: {
      ...headers,
      authorization: payload.refresh_token
        ? `Bearer ${payload.refresh_token}`
        : '',
    },
  };
});

export const refreshClient = new ApolloClient({
  link: refreshLink.concat(httpLink),
  cache: new InMemoryCache(),
});

export const authClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: from([errorLink, authLink.concat(httpLink)]),
});

export const client = new ApolloClient({
  link: httpLink,
  cache: new InMemoryCache(),
});
