import { Layout } from 'antd';
import styled from 'styled-components';
import NavBar from '~/pages/common/NavBar';
import Header from '~/pages/common/Header';
import User from '~/pages/user/User';

const { Content, Footer } = Layout;

function Settings() {
  return (
    <>
      <Layout style={{ minHeight: '100vh' }}>
        <NavBar />
        <Layout>
          <SContent>
            <Header />
            <User />
          </SContent>
          <Footer style={{ textAlign: 'center' }}>MySG ©2023 Starter</Footer>
        </Layout>
      </Layout>
    </>
  );
}

export default Settings;

const SContent = styled(Content)`
  padding: 2%;
`;
