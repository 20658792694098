export const env = {
  api: {
    url: import.meta.env.VITE_API_URL,
  },
  app: {
    url: import.meta.env.VITE_CLIENT_URL,
    env: import.meta.env.VITE_APP_ENV as
      | 'development'
      | 'local'
      | 'live'
      | 'staging'
      | 'test',
  },
};
