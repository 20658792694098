import { styled } from 'styled-components';
import bgImg3 from '@assets/image/background/bg-image-3.png';
import imgParallaxTop from '@assets/image/background/paralax-top-1.png';
import imgParallaxFooter from '@assets/image/background/paralax-footer.png';
import Slider from '~/utils/components/ui/Slider';
import bgGallery from '@assets/image/background/bg-gallery.png';
import galleryImg1 from '@assets/image/gallery/gallery-img-1.png';
import galleryImg2 from '@assets/image/gallery/gallery-img-2.png';
import galleryImg3 from '@assets/image/gallery/gallery-img-3.png';

type IProps = {
  agentIOs?: boolean;
};

function Gallery({ agentIOs }: IProps) {
  const image1 = (
    <SDivPage key={0}>
      <img alt="" src={galleryImg1} />
    </SDivPage>
  );
  const image2 = (
    <SDivPage key={1}>
      <img alt="" src={galleryImg2} />
    </SDivPage>
  );
  const image3 = (
    <SDivPage key={2}>
      <img alt="" src={galleryImg3} />
    </SDivPage>
  );

  return (
    <SParallaxGroup3 id="gallery" $agentios={agentIOs?.toString()}>
      <SImgTop alt="" src={imgParallaxTop} />
      <SDivGallery>
        <Slider
          autoPlay={false}
          autoPlayTime={2000}
          items={[image1, image2, image3]}
        />
      </SDivGallery>

      <SImgBottomBase alt="" src={imgParallaxFooter} />
    </SParallaxGroup3>
  );
}

export default Gallery;

const SParallaxGroup3 = styled.div<{
  $agentios?: string;
}>`
  position: relative;
  /*min-height: 100vh;*/
  transform-style: preserve-3d;

  align-items: center;
  background: ${({ $agentios }) =>
    $agentios
      ? `url(${bgImg3}) top/cover no-repeat`
      : `url(${bgImg3}) top/cover no-repeat fixed`};

  display: flex;
  flex-direction: column;
  /* min-height: 130vh;*/
  justify-content: space-between;
  z-index: 1;
  @media screen and (max-width: 1200px) {
    /* min-height: 120vh;*/
  }
  @media screen and (max-width: 885px) {
    /*  min-height: 110vh;*/
  }
  @media screen and (max-width: 767px) {
    /* min-height: 105vh;*/
  }
  @media screen and (max-width: 667px) {
    /*  min-height: 92vh;*/
  }
`;
const SImgTop = styled.img`
  height: 20%;
  margin-top: -2px;
  object-fit: corver;
  width: 100%;
`;
const SImgBottomBase = styled.img`
  height: 20%;
  object-fit: corver;
  margin-bottom: -1px;
  width: 100%;
`;

const SDivGallery = styled.div`
  place-self: center;
  margin: 50px;
  width: 90%;
  @media screen and (max-width: 992px) {
    margin: 30px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    margin: 20px;
    width: 100%;
  }
`;
const SDivPage = styled.div`
  background: url(${bgGallery}) center/contain no-repeat;
  height: 100%;
  position: relative;
  width: 100%;
  img {
    left: 0;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
