import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '~/helpers/store/auth';

function ImAuth() {
  const { imLoggedAndValid } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    // If im not logged or my token is not valid i redirect to login page
    if (!imLoggedAndValid()) {
      navigate('/');
      return;
    }
  }, []);
  return <></>;
}

export default ImAuth;
