import { useQuery } from '@apollo/client';
import { useState } from 'react';
import styled from 'styled-components';
import { GQL_USERS } from '~/gql/users/users';
import { authClient } from '~/helpers/apollo';
import { Table, FloatButton } from 'antd';
import type { ColumnsType } from 'antd/es/table';
import { RedoOutlined } from '@ant-design/icons';

type TUser = {
  uuid: string;
  email: string;
  updatedAt: string;
  firstName: string;
  lastName: string;
  role: {
    uuid: string;
    name: string;
  };
};

function User() {
  const [users, setUsers] = useState<TUser[]>([]);

  const { refetch: fetchUsers, loading } = useQuery(GQL_USERS, {
    client: authClient,
    onCompleted: (data) => {
      setUsers(data.getUsers);
    },
  });

  const handleRefreshUser = async () => {
    await fetchUsers();
  };

  const columns: ColumnsType<TUser> = [
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (_, { email }) => <TableElement>{email}</TableElement>,
    },
    {
      title: 'First Name',
      dataIndex: 'firstName',
      key: 'firstName',
      render: (_, { firstName }) => <TableElement>{firstName}</TableElement>,
    },
    {
      title: 'Last Name',
      dataIndex: 'lastName',
      key: 'lastName',
      render: (_, { lastName }) => <TableElement>{lastName}</TableElement>,
    },
    {
      title: 'Role',
      key: 'role',
      dataIndex: 'role',
      render: (_, { role }) => <TableElement>{role.name}</TableElement>,
    },
    {
      title: 'Last Connection',
      key: 'lastUpdate',
      dataIndex: 'lastUpdate',
      render: (_, { updatedAt }) => (
        <TableElement>{new Date(updatedAt).toLocaleString('fr')}</TableElement>
      ),
    },
  ];

  return (
    <div style={{ paddingTop: '5%' }}>
      {!loading && (
        <Table
          columns={columns}
          dataSource={users.map((e) => {
            return {
              ...e,
              key: e.uuid,
            };
          })}
        />
      )}
      <FloatButton
        onClick={handleRefreshUser}
        icon={<RedoOutlined style={{ fontSize: '20px' }} />}
        type="primary"
        style={{ right: 24, width: '50px', height: '50px', bottom: 24 }}
      />
    </div>
  );
}

export default User;

const TableElement = styled.a`
  color: #188;
`;
