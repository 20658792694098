import { Selector, InputType, GraphQLTypes } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

export const GQL_REPUTATION_PROGRESS = ({ email }: { email: string }) =>
  typedGql('query')({
    getReputation: [
      {
        email: email,
      },
      reputationSelector,
    ],
  });

const reputationSelector = Selector('ReputationProgress')({
  canopee: true,
  buisnessDistrict: true,
  lagon: true,
  residentialDistrict: true,
  hub: true,
});

export type StoredReputation = InputType<
  GraphQLTypes['ReputationProgress'],
  typeof reputationSelector
>;

export const GQL_ARC_QUESTS = ({ email }: { email: string }) =>
  typedGql('query')({
    getArcsProgress: [
      {
        email: email,
      },
      arcProgressSelector,
    ],
  });

const arcProgressSelector = Selector('TrackingProgress')({
  arcProgress: {
    arc1: {
      progress: true,
    },
    arc2: {
      progress: true,
    },
    arc3: {
      progress: true,
    },
    arc4: {
      progress: true,
    },
    arc5: {
      progress: true,
    },
    arc6: {
      progress: true,
    },
    arc7: {
      progress: true,
    },
    arc8: {
      progress: true,
    },
  },
  queteAnnexe: true,
  totalProgress: true,
});

export type StoredTrackingProgress = InputType<
  GraphQLTypes['TrackingProgress'],
  typeof arcProgressSelector
>;
