import { styled } from 'styled-components';
import BannerBackground from '@assets/image/background/bg-image-1.png';
import ButtonHoverBackground from '@assets/image/background/bg-button.png';
import paralaxBottom from '@assets/image/background/paralax-bottom-1.png';
import LogoE2C from '@assets/image/logo-e2c.png';
import LogoCenterHorizons from '@assets/image/logo-horizons-lg.png';
import { useState } from 'react';
import { Trans } from '@lingui/react';
import MenuDrawer from './MenuDrawer';
import { useAuth } from '~/helpers/store/auth';
import ButtonLink from '~/utils/components/ui/ButtonLink';
import { isMobile, isAndroid, isIOS } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

function Banner() {
  const [open, setOpen] = useState<boolean>(false);
  const { payload } = useAuth();
  const navigate = useNavigate();

  function showDrawer() {
    setOpen(true);
  }

  function closeDrawer() {
    setOpen(false);
  }
  return (
    <BannerContainer>
      <ImageDiv></ImageDiv>
      <BannerContent>
        <Header>
          <img src={LogoE2C} alt="logo e2c" />
          <div>
            <Menu>
              {!isMobile && (
                <a href="/game/index.html" target="_blank">
                  <ButtonLink>
                    <Trans id="common.play" />
                  </ButtonLink>
                </a>
              )}
              <a href="#about">
                <ButtonLink>
                  <Trans id="common.about" />
                </ButtonLink>
              </a>
              <a href="#gallery">
                <ButtonLink>
                  <Trans id="common.gallery" />
                </ButtonLink>
              </a>
              {!payload ? (
                <ButtonLink onClick={() => navigate('/login')}>
                  <Trans id="log_in" />
                </ButtonLink>
              ) : (
                <ButtonLink onClick={() => navigate('/dashboard')}>
                  Dashboard
                </ButtonLink>
              )}
            </Menu>
            <MobileMenu>
              {isAndroid && (
                <a href="unitydl://redirect" target="_blank">
                  <ButtonLink>
                    <Trans id="common.play" />
                  </ButtonLink>
                </a>
              )}
              {isIOS && (
                <a href="unitydl://redirect" target="_blank">
                  <ButtonLink>
                    <Trans id="common.play" />
                  </ButtonLink>
                </a>
              )}
              {!isMobile && !isAndroid && !isIOS && (
                <a href="/game/index.html" target="_blank">
                  <ButtonLink>
                    <Trans id="common.play" />
                  </ButtonLink>
                </a>
              )}
              <ButtonLink onClick={showDrawer}>
                <Trans id="common.menu" />
              </ButtonLink>
            </MobileMenu>
            <MenuDrawer open={open} closeDrawer={closeDrawer} />
          </div>
        </Header>
        <LogoHorizons>
          <img src={LogoCenterHorizons} alt="logo horizons" />
        </LogoHorizons>
        <ParalaxBottomDiv>
          <div>
            <h1>
              <Trans id="join.exciting.adventure" />
            </h1>
          </div>
        </ParalaxBottomDiv>
      </BannerContent>
    </BannerContainer>
  );
}

export default Banner;

const BannerContainer = styled.div`
  position: relative;
  min-height: 110vh;
  transform-style: preserve-3d;
  height: 100vh;
  z-index: 0;

  @media screen and (max-width: 667px) {
    height: 60vh;
  }

  @media screen and (max-width: 667px) {
    min-height: 100vh;
  }
`;

const BannerContent = styled.div`
  color: rgb(255, 255, 255);
  position: absolute;
  inset: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  transform: translateZ(0px);
`;

const Header = styled.div`
  align-items: flex-start;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 25px 25px 0px 50px;
  width: 100%;
  & > div {
    width: 100%;
  }
  & > img {
    width: 10%;
    @media screen and (max-width: 767px) {
      width: 15%;
    }
  }
  @media screen and (max-width: 420px) {
    padding: 10px 10px 0px 20px;
  }
  @media screen and (max-width: 767px) {
    -webkit-box-pack: justify;
    justify-content: space-between;
  }
`;

const Menu = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  flex-wrap: wrap;
  gap: 10px;
  width: 100%;

  & button {
    -webkit-box-align: center;
    align-items: center;
    background: transparent;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-family: MaliBold, sans-serif;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: normal;
    opacity: 1;
    padding: 20px;
    pointer-events: auto;

    &:hover {
      background: url(${ButtonHoverBackground}) center center / 100% 100%
        no-repeat;
    }
  }

  @media screen and (max-width: 767px) {
    height: 0px;
    opacity: 0;
    visibility: hidden;
    width: 0px;
  }

  @media screen and (max-width: 667px) {
    gap: 0;
  }
`;

const MobileMenu = styled.div`
  -webkit-box-align: center;
  align-items: center;
  display: flex;
  -webkit-box-pack: end;
  justify-content: flex-end;
  height: 0px;
  opacity: 0;
  visibility: hidden;
  width: 0px;

  & button {
    -webkit-box-align: center;
    align-items: center;
    background: transparent;
    color: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    font-size: 1rem;
    font-family: MaliBold, sans-serif;
    -webkit-box-pack: center;
    justify-content: center;
    letter-spacing: normal;
    opacity: 1;
    padding: 20px;
    pointer-events: auto;
    @media screen and (max-width: 667px) {
      padding: 10px 15px;
    }
    @media screen and (max-width: 415px) {
      font-size: 0.85rem;
    }
  }
  @media screen and (max-width: 767px) {
    height: 100%;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
`;

const LogoHorizons = styled.div`
  height: 30%;
  position: relative;

  & > img {
    height: 100%;
    object-fit: contain;
    top: 0px;
    width: 100%;
  }

  @media screen and (max-width: 667px) {
    width: 40%;
  }
`;

const ParalaxBottomDiv = styled.div`
  background: url(${paralaxBottom}) center center / 100% 100% no-repeat;
  height: 30%;
  margin-bottom: -1px;
  position: relative;
  width: 100%;
  z-index: 5;

  & > div {
    bottom: 0px;
    left: 0px;
    justify-self: end;
    position: absolute;
    width: 100%;

    & > h1 {
      color: rgb(255, 255, 255);
      margin-bottom: 30px;
      padding-top: 10px;
      text-align: center;
      width: 100%;

      @media screen and (max-width: 667px) {
        font-size: 1.3rem;
        margin-bottom: 10px;
      }
    }
  }
`;

const ImageDiv = styled.div`
  background: url(${BannerBackground}) center center / cover no-repeat;
  z-index: 1;
  color: rgb(255, 255, 255);
  position: absolute;
  inset: 0px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  transform: translateZ(-0.9px) scale(2);
`;
