import styled from 'styled-components';

import bg from '@assets/image/background/bg-title.png';
import iconLeft from '@assets/image/icons/icon-arrow-left.png';
import iconRight from '@assets/image/icons/icon-arrow-right.png';
import iconClose from '@assets/image/icons/icon-close.png';
import { classic } from '~/themes';

export const SSpacer = styled.div<{ $mt?: string; $mr?: string }>`
  margin-top: ${({ $mt }) => ($mt ? $mt : '40px')};
  margin-right: ${({ $mr }) => ($mr ? $mr : '0')};
`;

export const SParallaxSpacer = styled.div`
  margin-top: 160px;
  @media screen and (max-width: 1600px) {
    margin-top: 130px;
  }
  @media screen and (max-width: 1300px) {
    margin-top: 100px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 90px;
  }
  @media screen and (max-width: 670px) {
    margin-top: 80px;
  }
  @media screen and (max-width: 500px) {
    margin-top: 60px;
  }
  @media screen and (max-width: 415px) {
    margin-top: 50px;
  }
`;

export const SDivClose = styled.div`
  background: url(${iconClose}) top/contain no-repeat;
  cursor: pointer;
  height: 60px;
  right: 0;
  position: absolute;
  top: 0;
  width: 60px;
  @media screen and (max-width: 670px) {
    height: 40px;
    width: 40px;
  }
`;

export const SAvatarImg = styled.div<{ type?: 'sm' | 'md' }>`
  background-color: #ffff;
  border-radius: 6px;
  height: 100%;

  flex: ${({ type }) => {
    if (type === 'md') return '0 0 65px';
    if (type === 'sm') return '0 0 55px';

    return '0 0 55px';
  }};
  margin-right: 12px;
  position: relative;

  img {
    left: -113%;
    height: 330%;
    position: absolute;
    object-fit: contain;
    bottom: -200%;
    width: 330%;
  }
  @media screen and (max-width: 992px) {
    flex: ${({ type }) => {
      if (type === 'md') return '0 0 55px';
      if (type === 'sm') return '0 0 45px';

      return '0 0 45px';
    }};
  }
  @media screen and (max-width: 670px) {
    flex: ${({ type }) => {
      if (type === 'md') return '0 0 45px';
      if (type === 'sm') return '0 0 40px';

      return '0 0 40px';
    }};
  }
`;
export const SArrow = styled.button<{ hidden?: string }>`
  height: 54px;
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  top: 40%;
  visibility: ${({ hidden }) => (hidden ? 'hidden' : 'visible')};
  position: absolute;
  width: 54px;
  z-index: 5;

  @media screen and (max-width: 767px) {
    height: 44px;
    width: 44px;
  }
  @media screen and (max-width: 667px) {
    height: 34px;
    width: 34px;
  }
`;
export const SArrowLeft = styled(SArrow)`
  background: url(${iconLeft}) center/cover no-repeat;
  left: 0;
`;
export const SArrowRight = styled(SArrow)`
  background: url(${iconRight}) center/cover no-repeat;
  right: 0;
`;

export const SDots = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  margin-bottom: 30px;
  width: 100%;
  @media screen and (max-width: 667px) {
    margin-bottom: 50px;
  }
`;
export const SDot = styled.button<{ $current: string }>`
  background-color: ${({ $current }) =>
    $current === 'true' ? classic.token?.colorPrimary : `#f6e9d8`};
  border-radius: 50%;
  height: 16px;
  margin: 10px;
  width: 16px;
`;

export const SDivHeader = styled.div<{
  $justify?: 'space-between' | 'flex-start';
}>`
  align-items: flex-start;
  display: flex;
  justify-content: ${({ $justify }) => ($justify ? $justify : 'center')};
  left: 0px;
  padding: 25px 25px 0 50px;
  position: absolute;
  top: 0px;
  width: 100%;
  img {
    width: 10%;
    @media screen and (max-width: 667px) {
      width: 15%;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 20px 20px 0 40px;
  }
  @media screen and (max-width: 767px) {
    justify-content: space-between;
    padding: 15px 15px 0 30px;
  }
  @media screen and (max-width: 420px) {
    padding: 10px 10px 0 20px;
  }
`;
export const SDivDashboardHeader = styled.div<{
  justify?: 'space-between' | 'flex-start';
}>`
  align-items: flex-start;
  display: flex;
  justify-content: ${({ justify }) => (justify ? justify : 'center')};
  padding: 25px 25px 0 50px;
  width: 100%;
  img {
    width: 10%;
    @media screen and (max-width: 667px) {
      width: 15%;
    }
  }
  @media screen and (max-width: 992px) {
    padding: 20px 20px 0 40px;
  }
  @media screen and (max-width: 767px) {
    justify-content: space-between;
    padding: 15px 15px 0 30px;
  }
  @media screen and (max-width: 420px) {
    padding: 10px 10px 0 20px;
  }
`;

export const SDivMenuWrapper = styled.div`
  left: 0;
  height: 120px; /*padding-top de user-info, car necessite de background commun */
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

export const SDivListItems = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
  width: 60%;
  @media screen and (max-width: 992px) {
    gap: 25px;
    width: 70%;
  }
  @media screen and (max-width: 767px) {
    gap: 20px;
    width: 80%;
  }
  @media screen and (max-width: 667px) {
    width: 100%;
  }
`;

export const SDivFlexColumn = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 30px;
  margin-top: 40px;
  width: 60%;
  @media screen and (max-width: 767px) {
    gap: 20px;
    margin-top: 20px;
    width: 80%;
  }
  @media screen and (max-width: 667px) {
    gap: 10px;
    margin-top: 20px;
    width: 100%;
  }
`;

// eslint-disable-next-line react-refresh/only-export-components
export const SH1 = styled.h1<{ $textcolor?: 'primary' }>`
  color: ${({ $textcolor }) =>
    $textcolor
      ? classic.token?.colorPrimary
      : classic.token?.colorTextSecondary};
  margin-bottom: 30px;
  padding-top: 10px;
  text-align: center;
  width: 100%;
  @media screen and (max-width: 667px) {
    font-size: 1.3rem;
    margin-bottom: 10px;
  }
`;

export const SError = styled.p`
  color: #fff;
  font-family: 'MaliRegular', sans-serif;
  font-size: 0.9rem;
  margin: 0;
  line-height: 1rem;
  text-align: left;
`;

// ----------- login ----------------
export const SDivBlur = styled.div`
  align-items: center;
  backdrop-filter: blur(10px);
  background-blend-mode: multiply;
  background-image: linear-gradient(to bottom, #b3aea91a, #b3aea91a);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const SDivContentWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
  overflow-y: auto;
  padding-top: 60px;
  width: 100%;
  @media screen and (max-width: 600px) {
    padding-top: 40px;
  }
  @media screen and (max-width: 992px) {
    padding-top: 50px;
  }
`;
export const SDivLogoLogin = styled.div`
  margin-bottom: 10px;
  min-height: 180px;
  position: relative;
  width: 40%;
  img {
    position: absolute;
    left: 0;
    height: 100%;
    object-fit: contain;
    top: 0;
    width: 100%;
  }
  @media screen and (max-width: 992px) {
    min-height: 150px;
  }
  @media screen and (max-width: 767px) {
    min-height: 130px;
  }
`;
export const SSpacerLogin = styled.div`
  margin: 0px 0 30px 0;
  @media screen and (max-width: 767px) {
    margin: 0px 0 20px 0;
  }
  @media screen and (max-width: 450px) {
    margin: 0px 0 5px 0;
  }
`;

export const SDivConnect = styled.div<{ height?: string }>`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: ${({ height }) => (height ? height : 'auto')};
  width: 30%;
  z-index: 5;
  @media screen and (max-width: 992px) {
    width: 40%;
  }
  @media screen and (max-width: 767px) {
    width: 50%;
  }
  @media screen and (max-width: 667px) {
    width: 50%;
  }
  @media screen and (max-width: 450px) {
    width: 90%;
  }
`;
export const SDivHeaderLogin = styled(SDivHeader)`
  & > img {
    margin-right: 20px;
  }
  @media screen and (max-width: 767px) {
    & > img {
      margin-right: 10px;
    }
  }
`;

export const Form = styled.form`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > input {
    margin: 8px 0;
  }
`;
export const SDivImgBottomLogin = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  width: 100%;

  img {
    object-fit: cover;
    width: 100%;
  }
`;

export const ModalContainer = styled.div`
  align-items: center;
  background-color: #0f030cf0;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 20px;
  position: relative;

  width: 100%;

  & > p {
    color: #ffffff;
    width: 90%;
    margin-bottom: 20px;
    @media screen and (max-width: 667px) {
      margin-bottom: 10px;
    }
    & > a {
      background: url(${bg}) center / 100% 100% no-repeat;
      color: #ffffff;
      padding: 3px 10px;
    }
  }
  & > h1 {
    color: #ffffff;
    margin: 10px 0 20px 0;
  }
  & > h2 {
    color: #ffffff;
    margin: 15px 0 20px 0;
    text-align: center;
    @media screen and (max-width: 667px) {
      margin: 15px 0 10px 0;
    }
  }
`;
