import { styled } from 'styled-components';
import connectButton from '@assets/image/connect.png';
import connectAppleButton from '@assets/image/connect-apple.png';
import connectGoogleButton from '@assets/image/connect-google.png';
import logoHeader from '@assets/image/logo-e2c.png';
import logoMySG from '@assets/image/logo-mysg.png';
import Banner from './components/Banner';
import About from './components/About';
import Gallery from './components/Gallery';
import { Trans } from '@lingui/react';
import { SH1 } from '~/utils/styles';
import { classic } from '~/themes';
import Footer from '~/utils/components/Footer';
import { useEffect } from 'react';
import { parseJwt } from '~/utils/jwt';
import { Payload, useAuth } from '~/helpers/store/auth';
import { useNavigate } from 'react-router-dom';

function Home() {
  const { setPayload } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const jwt = window.location.search.split('jwt=')[1];

    if (jwt) {
      const dataJwt = parseJwt(jwt);

      if (!dataJwt) {
        return;
      }
      const payload: Payload = {
        access_token: jwt,
        refresh_token: '',
        firstName: dataJwt.given_name ?? '',
        lastName: dataJwt.family_name ?? '',
        exp: dataJwt.exp,
        iat: dataJwt.iat,
        sub: dataJwt.sub,
        email: dataJwt.unique_name ?? '',
      };

      setPayload(payload);
      navigate('/');
    }
  }, []);
  return (
    <Container>
      <SDivWrapper>
        <Banner />
        <SDivTextGroup>
          <p>
            <Trans id="subscribe.message" />
          </p>
          <SDivLinks>
            <a
              href="/game/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={connectButton} alt="e2c online image" />
            </a>
            <a
              href="https://apps.apple.com/fr/app/horizons-le-jeu-de-ton-avenir/id6444684639"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={connectAppleButton} alt="e2c app store" />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.MySeriousGame.E2CHorizons&pli=1"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={connectGoogleButton} alt="e2c google play" />
            </a>
          </SDivLinks>
        </SDivTextGroup>
      </SDivWrapper>
      <About />
      <Gallery />
      <SDivWrapper>
        <SDivTextGroup>
          <SH1 $textcolor="primary">
            <Trans id="common.partnership" />
          </SH1>
          <SDivLogosFooter>
            <img alt="logo e2c" src={logoHeader} />
            <img alt="logo mysg" src={logoMySG} />
          </SDivLogosFooter>
        </SDivTextGroup>
      </SDivWrapper>
      <SDivFooter>
        <Footer />
      </SDivFooter>
    </Container>
  );
}

export default Home;

const Container = styled.div`
  height: 100vh;
  overflow: hidden auto;
  perspective: 1px;
  width: 100%;
`;

const SDivWrapper = styled.div`
  position: relative;
  transform-style: preserve-3d;
`;

const SDivTextGroup = styled.div`
  align-items: center;
  background-color: rgb(15, 3, 12);
  display: flex;
  flex-direction: column;
  justify-content: center;
  z-index: 10;

  & > p {
    font-family: 'MaliRegular', sans-serif;
    color: ${classic.token?.colorTextSecondary};
    padding-top: 10px;
    text-align: center;
    width: 40%;
    @media screen and (max-width: 667px) {
      width: 80%;
    }
  }
`;

const SDivLinks = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 40px;

  width: 100%;

  & a {
    flex: 0 0 20%;
    margin: 5px;
    min-height: 50px;
    position: relative;
    width: 20%;

    & img {
      height: 100%;
      left: 0px;
      object-fit: contain;
      top: 0px;
      position: absolute;
      width: 100%;
    }
    @media screen and (max-width: 415px) {
      flex: 0 0 24%;
    }
  }
`;

const SDivLogosFooter = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;

  height: 80px;
  gap: 20px;
  margin-bottom: 100px;
  width: 100%;

  @media screen and (max-width: 290px) {
    flex-wrap: wrap;
    height: 100px;
  }
  & > img {
    height: 100%;
    @media screen and (max-width: 290px) {
      height: 50%;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
  }
`;

const SDivFooter = styled.div`
  position: relative;
  transform-style: preserve-3d;
  z-index: 4;
`;
