import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { useNavigate } from 'react-router-dom';

import ButtonSm from '~/utils/components/ui/ButtonSm';
import bgImg from '@assets/image/background/bg-image-1.png';

import imgParallaxBottom from '@assets/image/background/paralax-bottom-1.png';
import logoHeader from '@assets/image/logo-e2c.png';
import logoLg from '@assets/image/logo-horizons-lg.png';
import {
  SSpacer,
  SDivBlur,
  SDivContentWrapper,
  SDivLogoLogin,
  SSpacerLogin,
  SDivHeaderLogin,
  SDivImgBottomLogin,
} from '~/utils/styles';
import Footer from '~/utils/components/Footer';
import { env } from '~/helpers/env';
import ButtonLogin from '~/pages/login/ButtonLogin';
import { parseJwt } from '~/utils/jwt';
import { useEffect } from 'react';
import { Payload, useAuth } from '~/helpers/store/auth';

function LoginSSO() {
  const navigate = useNavigate();
  const { setPayload } = useAuth();

  useEffect(() => {
    const error = window.location.search.split('error=')[1];
    if (error) {
      alert(`ERROR SSO : ${window.location.search.split('error=')[1]}`);
      return;
    }
    const jwt = window.location.search.split('jwt=')[1];
    if (jwt) {
      const dataJwt = parseJwt(jwt);
      if (!dataJwt) {
        return;
      }

      const payload: Payload = {
        access_token: jwt,
        refresh_token: '',
        firstName: dataJwt.given_name ?? '',
        lastName: dataJwt.family_name ?? '',
        exp: dataJwt.exp,
        iat: dataJwt.iat,
        sub: dataJwt.sub,
        email: dataJwt.unique_name ?? '',
      };

      setPayload(payload);
      navigate('/dashboard');
    }
  }, []);

  return (
    <Container>
      <SDivBlur>
        <SDivContentWrapper>
          <SDivHeaderLogin $justify="flex-start">
            <img alt="" src={logoHeader} />
            <ButtonSm
              stylebold
              onClick={() => navigate('/')}
              textTransform="lowercase"
            >
              <Trans id="commun.back" />
            </ButtonSm>
          </SDivHeaderLogin>
          <SDivLogin>
            <SDivLogoLogin>
              <img alt="" src={logoLg} />
            </SDivLogoLogin>
            <SSpacerLogin />
            <ButtonLogin
              typeLogin="microsoft"
              type="button"
              onClick={() =>
                (location.href = `${env.api.url}/azure-ad/connect`)
              }
            >
              <Trans id="log_in_sso" />
            </ButtonLogin>

            <SSpacer $mt="3px" />
          </SDivLogin>
          <SDivImgBottomLogin>
            <img alt="" src={imgParallaxBottom} />
            <Footer />
          </SDivImgBottomLogin>
        </SDivContentWrapper>
      </SDivBlur>
    </Container>
  );
}

export default LoginSSO;

const Container = styled.div`
  align-items: center;
  background: url(${bgImg}) center/cover no-repeat fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100%;
`;
const SDivLogin = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 90%;
  z-index: 5;
`;
