import styled from 'styled-components';
import { Progress } from 'antd';
import bgStats from '@assets/image/background/bg-stats.png';
import { classic } from '~/themes';

type IProps = {
  progression: number;
};

function UserProgression({ progression }: IProps) {
  return (
    <Container>
      <p>Progression</p>
      <Progress
        type="circle"
        percent={progression}
        size={130}
        strokeWidth={13}
        trailColor="#0f030c"
        strokeColor={classic.token?.colorPrimary}
      />
    </Container>
  );
}
export default UserProgression;

const Container = styled.div`
  & p:nth-child(1) {
    color: rgb(231, 93, 93);
    margin-bottom: 8px;
    text-align: center;
    width: 100%;
  }
  background: url(${bgStats}) center / 100% 100% no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 20px 30px;
  margin: 35px 0 15px 0;
  width: 100%;
`;
