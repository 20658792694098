import React from 'react';
import { styled } from 'styled-components';

import logo from '@assets/image/logo-horizons-sm.png';
import bgHeaderRigth from '@assets/image/background/bg-header.png';

import { useState } from 'react';
import { Trans } from '@lingui/react';
import MenuDrawer from './MenuDrawer';

import { useNavigate } from 'react-router-dom';
import ButtonSm from '~/utils/components/ui/ButtonSm';

type IProps = {
  children?: React.ReactNode;
  withbackground?: boolean;
  withLogo?: boolean;
};

function Menu({ withbackground, withLogo }: IProps) {
  const [open, setOpen] = useState<boolean>(false);
  const navigate = useNavigate();

  function showDrawer() {
    setOpen(true);
  }

  return (
    <Container
      $withbackground={withbackground?.toString()}
      withLogo={withLogo?.toString()}
    >
      <SMenuWrapper>
        <SLogoImg onClick={() => navigate('/')}>
          <img alt="" src={logo} />
        </SLogoImg>
        <SDivContent>
          <SLeftMenuContent />
          <SMenuContent>
            <SMenuScrollable>
              <a href="/game/index.html" target="_blank">
                <ButtonSm stylebold withhover>
                  Jouer
                </ButtonSm>
              </a>
              <a href="#summary">
                <ButtonSm stylebold withhover>
                  <Trans id="menu.summary" />
                </ButtonSm>
              </a>
              <a href="#success">
                <ButtonSm stylebold withhover>
                  <Trans id="menu.success" />
                </ButtonSm>
              </a>
              <a href="#progression">
                <ButtonSm stylebold withhover>
                  <Trans id="menu.progression" />
                </ButtonSm>
              </a>
              <a href="#quests">
                <ButtonSm stylebold withhover>
                  <Trans id="menu.quests" />
                </ButtonSm>
              </a>
              <a href="#reputation">
                <ButtonSm stylebold withhover>
                  <Trans id="menu.reputation" />
                </ButtonSm>
              </a>
              <a href="#logout">
                <ButtonSm
                  stylebold
                  withhover
                  onClick={() => navigate('/logout')}
                  textcolor="primary"
                >
                  <Trans id="menu.logout" />
                </ButtonSm>
              </a>
            </SMenuScrollable>
            <SMenuMobil>
              <ButtonSm stylebold withhover onClick={showDrawer}>
                <Trans id="common.menu" />
              </ButtonSm>
            </SMenuMobil>
          </SMenuContent>
          <SRigthMenuContent />
        </SDivContent>
      </SMenuWrapper>
      <MenuDrawer closeDrawer={() => setOpen(false)} open={open} />
    </Container>
  );
}

export default Menu;

const Container = styled.div<{
  $withbackground?: string;
  withLogo?: string;
}>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 120px;
  padding: 0 35px 0 50px;
  width: 100%;

  @media screen and (max-width: 992px) {
    padding: 0 15px 0 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 0 10px 0 20px;
  }
  @media screen and (max-width: 667px) {
    padding: 0 5px;
  }
`;
const SMenuWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  flex-direction: row;
  position: relative;
  width: 100%;
`;
const SMenuContent = styled.div`
  background-color: #0f030c;
  height: 60px;
  overflow: hidden;
  width: 100%;
`;
const SMenuMobil = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  height: 0;
  opacity: 0;
  visibility: hidden;
  width: 0;
  @media screen and (max-width: 1120px) {
    height: 100%;
    opacity: 1;
    visibility: visible;
    width: 100%;
  }
`;
// @media screen and (max-width: 767px) { pour menuMobil

const SMenuScrollable = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;

  @media screen and (max-width: 1120px) {
    height: 0;
    opacity: 0;
    visibility: hidden;
    width: 0;
  }

  & > a:nth-child(1) {
    order: 8;
  }
  & > a:nth-child(2) {
    order: 7;
  }
  & > a:nth-child(3) {
    order: 6;
  }
  & > a:nth-child(4) {
    order: 5;
  }
  & > a:nth-child(5) {
    order: 4;
  }
  & > a:nth-child(6) {
    order: 3;
  }
  & > a:nth-child(7) {
    order: 2;
  }
  & > a:nth-child(8) {
    order: 1;
  }
`;
//  @media screen and (max-width: 767px) { pour menuScrollable

const SLeftMenuContent = styled.div`
  background-color: #0f030c;
  height: 60px;
  flex: 0 0 100px;
`;
const SRigthMenuContent = styled.div`
  background: url(${bgHeaderRigth}) center/100% 100% no-repeat;
  height: 60px;
  flex: 0 0 20px;
`;

const SLogoImg = styled.div`
  height: 100%;
  position: absolute;
  left: -10px;
  cursor: pointer;
  width: 120px;
  z-index: 5;

  @media screen and (max-width: 670px) {
    left: 0px;
  }

  img {
    position: absolute;
    left: 0;
    top: 0;
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
`;
const SDivContent = styled.div`
  align-items: center;
  flex-direction: row;
  display: flex;
  justify-content: flex-start;
  height: 100%;
  position: relative;
  width: 100%;
  & > a > button {
    font-size: 0.85rem;
  }
`;
