import React from 'react';
import styled from 'styled-components';

import bgButtonLogin from '@assets/image/background/bg-button-login.png';
import iconMicrosoft from '@assets/image/icons/logo-microsoft.png';
import { SSpacer } from '~/utils/styles';

type IProps = {
  color?: 'primary' | 'secondary';
  textcolor?: string;
  onClick?: () => void;
  children: React.ReactNode;
  disabled?: boolean;
  hidden?: boolean;
  type?: 'button' | 'submit' | 'reset';
  typeLogin?: 'microsoft';
};

function ButtonLogin({
  color,
  textcolor,
  onClick,
  children,
  typeLogin,
  disabled,
  hidden,
  type,
}: IProps) {
  return (
    <Container
      color={color}
      disabled={disabled?.toString()}
      $typelogin={typeLogin}
      hidden={hidden?.toString()}
      onClick={onClick}
      $textcolor={textcolor}
      type={type}
    >
      {typeLogin === 'microsoft' && (
        <>
          <SIcon>
            <img src={iconMicrosoft} alt="" />
          </SIcon>
          <SSpacer $mr="8px" $mt="0" />
        </>
      )}

      <span>{children}</span>
    </Container>
  );
}

export default ButtonLogin;

const Container = styled.button<{
  color?: 'primary' | 'secondary';
  disabled?: string;
  hidden?: string;
  $textcolor?: string;
  $typelogin?: 'microsoft';
}>`
  align-items: center;
  background: ${({ $typelogin }) => {
    if ($typelogin) {
      return `url(${bgButtonLogin}) center/100% 100% no-repeat`;
    }
    return 'none';
  }};
  display: flex;
  flex-direction: row;
  height: 75px;
  font-family: 'MaliBold', sans-serif;
  font-size: 1rem;
  justify-content: center;
  letter-spacing: normal;
  opacity: ${({ disabled, hidden }) => {
    if (hidden) return 0;
    if (disabled) return 0.7;

    return 1;
  }};
  padding: 12px 20px;
  pointer-events: ${({ disabled, hidden }) =>
    disabled ?? hidden ? 'none' : 'auto'};

  @media screen and (max-width: 767px) {
    height: 60px;
  }

  & > span {
    color: ${({ $textcolor }) => ($textcolor ? $textcolor : '#ffffff')};
    font-family: 'MaliBold', sans-serif;
    font-size: 1rem;
    letter-spacing: normal;
    text-transform: uppercase;
    @media screen and (max-width: 415px) {
      font-size: 0.9rem;
    }
  }
`;

const SIcon = styled.div`
  height: 30px;
  position: relative;
  width: 30px;

  img {
    left: 0;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
