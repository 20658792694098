import styled from 'styled-components';
import { Trans } from '@lingui/react';
import ButtonSm from '~/utils/components/ui/ButtonSm';
import bgImg from '@assets/image/background/bg-image-1.png';
import {
  SDivBlur,
  SDivContentWrapper,
  SDivHeaderLogin,
  SSpacerLogin,
} from '~/utils/styles';
import { useNavigate } from 'react-router-dom';

function Rgpd() {
  const navigate = useNavigate();

  return (
    <Container>
      <SDivBlur>
        <SDivContentWrapper>
          <SDivHeaderLogin $justify="flex-start">
            <ButtonSm
              stylebold
              onClick={() => navigate('/')}
              textTransform="lowercase"
            >
              <Trans id="commun.back" />
            </ButtonSm>
          </SDivHeaderLogin>
          <SDivLogin>
            <SSpacerLogin />
            <h1>Politique de confidentialité </h1>
            <p>
              L’éditeur du présent site internet a pour principe et obligation
              de protéger la vie privée de tous ses utilisateurs. En effet, nous
              collectons des informations, incluant des données personnelles,
              lorsque vous vous inscrivez ou naviguez sur le site. Egalement,
              lorsque vous nous envoyez des messages ou bien lorsque vous
              remplissez un formulaire sur le site. Ces informations et données
              personnelles vous concernant, comprennent par exemple : votre nom,
              prénom, société, adresse postale, e-mail, numéro de téléphone,
              ainsi que toute autre information personnellement identifiable.
            </p>
            <p>
              Nous pouvons aussi recueillir et analyser certaines données qui
              nous sont envoyées automatiquement par l’ordinateur et qui sont
              liées à l’utilisation du site. Par exemple : le nom de domaine, le
              nombre de clics, les pages visitées, les sites précédents/suivants
              visités ainsi que la durée de la session d’utilisateur. Ces
              données peuvent être recueillies par le biais de cookies.
            </p>
            <p>
              Dans tous les cas, nous ne louons, ni vendons pas vos données
              personnelles à des tierces parties. Ces dernières servent
              exclusivement à vous apporter les meilleurs réponses et services
              (devis, facturation, newsletter…). Sachez que nous conservons les
              données marketing 3 ans maximum et les données liées à la
              facturation des commandes 6 ans maximum.
            </p>
            <p>
              A tout moment, vous avez le droit de rectifier, modifier,
              supprimer ou recueillir (au format .csv) vos données à caractère
              personnel. Pour cela, il vous suffit simplement de nous contacter
              en utilisant le formulaire de contact mis à votre disposition sur
              ce site.
            </p>
            <SSpacerLogin />
            <h1>Gestion des cookies</h1>
            <p>
              Lors de la consultation du présent site, des cookies peuvent être
              déposés sur votre ordinateur, votre smartphone, votre tablette ou
              tout autre terminal utilisé. Sachez qu’un cookie est un fichier
              texte déposé sur votre appareil lors de la navigation sur un site
              ou bien lors de la consultation d’une publicité en ligne. Ce
              dernier a pour but de collecter des informations relatives à votre
              navigation afin de vous adresser des services mieux adaptés. Les
              cookies sont gérés par votre navigateur internet (Microsoft Edge,
              Internet Explorer, Firefox, Google Chrome, Safari, Android
              Browser…).
            </p>
            <p>
              A tout moment, vous avez la possibilité de désactiver
              l’utilisation de ces cookies. En effet, votre navigateur peut être
              paramétré afin de vous signaler les cookies qui sont déposés et de
              vous demander de les accepter ou non. Ainsi, vous avez le choix
              d’accepter ou de refuser les cookies, au cas par cas, ou bien les
              refuser définitivement.
            </p>
            <p>
              Attention : nous vous informons que ce paramétrage est susceptible
              de modifier vos conditions d’accès aux contenus et services de
              notre site nécessitant l’utilisation de cookies. N’hésitez-pas à
              nous contacter si besoin. Une fois de plus, sachez que dans tous
              les cas, nous ne louons, ni vendons pas vos données personnelles à
              des tierces parties.
            </p>
            <SSpacerLogin />
            <h1>Sécurisation de vos données </h1>
            <p>
              Nous mettons toutes les mesures de sécurité en place pour assurer
              la protection de vos données, ainsi que la manière dont vous
              pouvez exercer votre droit de modification ou de suppression de
              ces données. A savoir : hébergement sur nos serveurs infogérés,
              certificat SSL domaine (HTTPS – cryptage), sauvegarde quotidienne,
              infogérance sécurité (surveillance active des serveurs), actions
              de prévention des attaques (mécanismes anti-DDoS), mises à jour de
              sécurité, information client et CNIL dans les 72 heures en cas de
              faille de sécurité.
            </p>
            <SSpacerLogin />
            <h1> Conditions inhérentes à l’utilisation du site internet</h1>
            <p>
              L’éditeur du présent site internet détient et exploite ce site.
              Son utilisation implique que vous en acceptez l’ensemble des
              termes, conditions et notifications contenus et cités dans ce
              document (mentions légales/conditions d’utilisation). Ces
              dernières ne s’appliquent qu’au présent site et à ses services, et
              en aucun cas à un site tiers.
            </p>
            <p>
              Dans le cas où vous n’acceptez pas ces conditions, merci de ne pas
              utiliser ce site. Nous nous réservons le droit, à notre
              discrétion, de mettre à jour ou de modifier les mentions légales
              et conditions d’utilisation. Par conséquent, veuillez vérifier
              régulièrement ces dernières afin de savoir si elles ont été
              modifiées ou pas. Nous considérons que vous acceptez une
              modification des conditions d’utilisation si vous continuez à
              utiliser le site après publication de cette modification. Merci de
              votre compréhension.
            </p>
            <p>
              N’hésitez-pas à nous contacter via notre formulaire pour plus
              d’informations sur le sujet.
            </p>
            <Spacer />
          </SDivLogin>
        </SDivContentWrapper>
      </SDivBlur>
    </Container>
  );
}

export default Rgpd;

const Spacer = styled.div`
  padding: 20px;
`;

const Container = styled.div`
  align-items: center;
  background: url(${bgImg}) center/cover no-repeat fixed;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  position: relative;
  width: 100%;
`;
const SDivLogin = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  width: 90%;
  z-index: 5;
`;
