import styled from 'styled-components';

import { IDistrict } from '~/pages/dashboard/types';
import { getGamerNameByType } from '~/pages/dashboard/utils';

type IProps = {
  district: IDistrict;
  color?: 'green' | 'yellow' | 'blue' | 'pink' | 'violet';
};

function Progress({ color, district }: IProps) {
  return (
    <Container color={color}>
      <p>{district.name}</p>
      <SProgressWrapper>
        <SProgress color={color} width={district.value}></SProgress>
      </SProgressWrapper>
      <p>{getGamerNameByType(district.value)}</p>
    </Container>
  );
}

export default Progress;

const Container = styled.div<{
  color?: 'green' | 'yellow' | 'blue' | 'pink' | 'violet';
}>`
  align-items: center;
  display: flex;
  justify-content: space-between;
  gap: 20px;
  margin-top: 10px;
  padding: 0 5px;
  height: 20px;
  width: 100%;

  & > p {
    color: #fff;
    font-size: 0.9rem;
    font-family: 'MaliBold', sans-serif;
  }
  & > p:nth-child(1) {
    flex: 0 0 25%;
  }
  & > p:nth-child(3) {
    flex: 0 0 25%;
  }
`;

const SProgressWrapper = styled.div<{
  color?: 'green' | 'yellow' | 'blue' | 'pink' | 'violet';
}>`
  align-items: center;
  border: 1px solid #fff;
  background-color: #ffffff4d;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  flex: 0 1 50%;
  overflow: hidden;
  position: relative;
  height: 20px;
  width: 100%;
`;
const SProgress = styled.div<{
  width?: number;
  color?: 'green' | 'yellow' | 'blue' | 'pink' | 'violet';
}>`
  align-items: center;
  background: ${({ color }) => {
    if (color === 'green') return 'linear-gradient(to left, #8fe67b, #3fac63)';
    if (color === 'yellow') return 'linear-gradient(to left, #ffcc6e, #e0a020)';
    if (color === 'blue') return 'linear-gradient(to left, #7be6e4, #3f83ac)';
    if (color === 'pink') return 'linear-gradient(to left, #db7be6, #8c1065)';
    if (color === 'violet') return 'linear-gradient(to left, #8f7be6, #31108c)';

    return 'transparent';
  }};
  border-radius: 8px;
  display: flex;
  justify-content: center;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: ${({ width }) => `${width}%`};
`;
