import { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useLingui } from '@lingui/react';
import moment from 'moment';

import Title from '~/utils/components/ui/Title';
import Slider from '~/utils/components/ui/Slider';
import { ISuccess } from '~/pages/dashboard/types';
import SuccessItem from '~/utils/components/ui/SuccessItem';
import { getPathSuccess } from '~/pages/dashboard/utils';
import { useAuth } from '~/helpers/store/auth';
import { StoredSuccess } from '~/gql/success/success';

type IProps = {
  success: StoredSuccess[];
};

function Success({ success }: IProps) {
  const { i18n } = useLingui();
  const [items, setItems] = useState<any>([]);
  const [listsArr, setListsArr] = useState<ISuccess[][]>([]);
  const { payload } = useAuth();

  useEffect(() => {
    function getSuccess() {
      const _successList: ISuccess[] = success.map((el: StoredSuccess) => {
        const _newEl: ISuccess = {
          name: el.name,
          description: el.description,
          unlock: el.unlock
            ? moment(el.unlock).format('DD/MM/YYYY').toString()
            : '',
          code: el.code,
          image: el.image ? getPathSuccess(el.image) : '',
        };
        return _newEl;
      });
      const _arr = completeItemLists(_successList);

      setListsArr(_arr);
    }

    getSuccess();
  }, [success]);

  useEffect(() => {
    const jsxList = createJsxList(listsArr);
    setItems(jsxList);
  }, [listsArr]);

  function completeItemLists(list: ISuccess[]): ISuccess[][] {
    const _newlist = [...list];
    const _arr = [];
    while (_newlist.length > 0) {
      const itemList =
        _newlist.length > 10
          ? _newlist.splice(0, 10)
          : _newlist.splice(0, _newlist.length);
      _arr.push(itemList);
    }

    return _arr;
  }

  function createJsxList(lists: ISuccess[][]) {
    const jsxArr = [];
    if (lists.length === 0) return [];

    for (const list of lists) {
      const jsx = list.map((item, ind) => (
        <SuccessItem item={item} key={ind} />
      ));
      const jsxElem = <div className="success">{jsx}</div>;
      jsxArr.push(jsxElem);
    }

    return jsxArr;
  }

  if (!payload) return null;

  return (
    <Container id="success">
      <Title withbackground text={i18n._('menu.success')} />
      <SSpacer />
      <SDivWrapper>
        {listsArr.length > 0 && (
          <Slider autoPlay={false} autoPlayTime={2000} items={items} />
        )}
      </SDivWrapper>
    </Container>
  );
}

export default Success;

const Container = styled.div`
  align-items: center;
  background: #0f030c;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 35px 40px 60px 40px;
  /* min-height: 80vh;*/
  transform-style: preserve-3d;
  position: relative;
  width: 100%;

  @media screen and (max-width: 992px) {
    padding: 30px 30px 40px 30px;
  }
  @media screen and (max-width: 767px) {
    padding: 20px 15px 35px 15px;
  }
  @media screen and (max-width: 667px) {
    padding: 15px 10px 35px 10px;
  }
  @media screen and (max-width: 500px) {
    padding: 15px 5px 35px 5px;
  }
  & > p {
    color: #fff;
  }
`;

const SSpacer = styled.div`
  margin-top: 30px;
`;

const SDivWrapper = styled.div`
  width: 90%;
  @media screen and (max-width: 992px) {
    width: 100%;
  }
`;
