import { gql } from '@apollo/client';

export const GQL_USERS = gql`
  query {
    getUsers {
      email
      updatedAt
      uuid
      firstName
      lastName
      role {
        name
        uuid
      }
    }
  }
`;
