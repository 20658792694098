import { useEffect } from 'react';
import { useAuth } from '~/helpers/store/auth';
import { useLingui } from '@lingui/react';
import './Login.css';

function Logout() {
  const { i18n } = useLingui();

  const { logout } = useAuth();
  useEffect(() => {
    logout();
    setTimeout(() => {
      window.location.href = '/';
    }, 1000);
  }, []);

  return (
    <>
      <p style={{ color: 'white' }}>{i18n._('logout.redirect.message')}</p>
    </>
  );
}

export default Logout;
