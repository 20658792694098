import { Selector, InputType, GraphQLTypes } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

export const GQL_AVATAR = ({ email }: { email: string }) =>
  typedGql('query')({
    getAvatar: [
      {
        email: email,
      },
      avatarSelector,
    ],
  });

const avatarSelector = Selector('Avatar')({
  uuid: true,
  face: true,
  color: true,
  hat: {
    name: true,
    image: true,
    rarity: true,
  },
  bottom: {
    name: true,
    image: true,
    rarity: true,
  },
  top: {
    name: true,
    image: true,
    rarity: true,
  },
});

export type StoredAvatar = InputType<
  GraphQLTypes['Avatar'],
  typeof avatarSelector
>;
