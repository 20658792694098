import React from 'react';
import { Route } from 'react-router-dom';
import App from '~/pages/App';
import Dashboard from '~/pages/dashboard/Dashboard';
import Settings from '~/pages/settings/Settings';
import LoginSSO from '~/pages/login/LoginSSO';
import ImAuth from '~/pages/utils/ImAuth';
import Logout from '~/pages/login/Logout';
import Denied from '~/pages/login/Denied';
import Rgpd from '~/pages/rgpd/Rgpd';

export enum Role {
  Admin = 'Admin',
  Guest = 'Guest',
  User = 'User',
}

type RouteParam = {
  path: string;
  element: React.JSX.Element;
  role: Role;
};

// Routes à définir
const routes: RouteParam[] = [
  {
    path: '/',
    element: <App />,
    role: Role.Guest,
  },
  {
    path: '/rgpd',
    element: <Rgpd />,
    role: Role.Guest,
  },
  {
    path: '/login',
    element: <LoginSSO />,
    role: Role.Guest,
  },
  {
    path: '/dashboard',
    element: <Dashboard />,
    role: Role.User,
  },
  {
    path: '/settings',
    element: <Settings />,
    role: Role.Admin,
  },
  {
    path: '/logout',
    element: <Logout />,
    role: Role.Guest,
  },
  {
    path: '/denied',
    element: <Denied />,
    role: Role.Guest,
  },
];

export const renderRoutes = () => {
  return routes.map((r, i) => {
    return (
      <Route
        path={r.path}
        element={
          r.role != Role.Guest ? (
            <>
              <ImAuth />
              {r.element}
            </>
          ) : (
            r.element
          )
        }
        key={i}
      />
    );
  });
};
