import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { ApolloProvider } from '@apollo/client';
import { BrowserRouter, Routes } from 'react-router-dom';
import { ConfigProvider } from 'antd';
import { classic } from '~/themes/index';
import { client } from '~/helpers/apollo';
import { catalogs } from '~/locales/catalogs';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';

import './index.css';
import { renderRoutes } from '~/pages/utils/router';
import useLanguageStore from '~/helpers/store/locale/locale';

export function Main() {
  i18n.load(catalogs);
  const { locale } = useLanguageStore();

  useEffect(() => {
    i18n.activate(locale);
  }, [locale]);

  return (
    <React.StrictMode>
      <I18nProvider i18n={i18n}>
        <ConfigProvider theme={classic}>
          <ApolloProvider client={client}>
            <BrowserRouter>
              <Routes>{renderRoutes()}</Routes>
            </BrowserRouter>
          </ApolloProvider>
        </ConfigProvider>
      </I18nProvider>
    </React.StrictMode>
  );
}

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <Main />
);
