import styled from 'styled-components';
import { Trans } from '@lingui/react';
import { IRewardsImagesUnlocked } from '~/pages/dashboard/types';
import bgRewards from '@assets/image/background/bg-rewards.png';
import { classic } from '~/themes';

type IProps = {
  gridarea?: string;
  rewardsCount: any | undefined;
  rewardsImages: IRewardsImagesUnlocked | undefined;
};

function UserRewards({ gridarea, rewardsCount, rewardsImages }: IProps) {
  if (!rewardsCount || !rewardsImages) return <></>;

  return (
    <Container $gridarea={gridarea}>
      <SDivImages>
        <SDivImg type="hat">
          {rewardsImages.hatImages.map((hat, index) => (
            <img
              alt={hat.split('/')[hat.split('/').length - 1].split('.png')[0]}
              key={hat + index}
              src={hat}
            />
          ))}
          <p>{rewardsImages.hatRariry}</p>
        </SDivImg>

        <SDivImg type="top">
          {rewardsImages.topImages.map((top, index) => (
            <img
              alt={top.split('/')[top.split('/').length - 1].split('.png')[0]}
              key={top + index}
              src={top}
            />
          ))}
          <p>{rewardsImages.topRarity}</p>
        </SDivImg>

        <SDivImg type="bottom">
          {rewardsImages.bottomImages.map((bottom, index) => (
            <img
              alt={
                bottom.split('/')[bottom.split('/').length - 1].split('.png')[0]
              }
              key={bottom + index}
              src={bottom}
            />
          ))}
          <p>{rewardsImages.bottomRarity}</p>
        </SDivImg>
      </SDivImages>

      <SDivInfo>
        <SDivInfoItem>
          <span>
            <Trans id="common.rewards.unlocked" />
          </span>
          <span>{rewardsCount.total}</span>
        </SDivInfoItem>
        <SDivInfoItem>
          <span>
            <Trans id="common.rewards.epic" />
          </span>
          <span>{rewardsCount.epic}</span>
        </SDivInfoItem>
        <SDivInfoItem>
          <span>
            <Trans id="common.rewards.legendary" />
          </span>
          <span>{rewardsCount.legendary}</span>
        </SDivInfoItem>
      </SDivInfo>
    </Container>
  );
}
/*
 
*/
export default UserRewards;

const Container = styled.div<{
  $gridarea?: string;
}>`
  align-items: center;
  background: url(${bgRewards}) center / 100% 100% no-repeat;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: center;
  height: auto;
  padding: 20px 30px;
  width: 100%;
  @media screen and (max-width: 667px) {
    padding: 10px 10px;
  }
`;
const SDivImages = styled.div`
  flex: 0 0 45%;
  align-items: center;
  display: flex;
  justify-content: space-around;
  gap: 10px;
  width: 100%;
`;

const SDivImg = styled.div<{ type: 'hat' | 'top' | 'bottom' }>`
  position: relative;
  width: 30%;
  @media screen and (min-width: 1500px) {
    width: 25%;
  }
  @media screen and (min-width: 1700px) {
    width: 20%;
  }
  @media screen and (min-width: 2300px) {
    width: 15%;
  }
  &:after {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  & > img:nth-child(1) {
    left: 0;
    height: 100%;
    top: 0;
    object-fit: contain;
    position: absolute;
    width: 100%;
  }
  & > img:nth-child(2) {
    bottom: 0px;
    left: 0;
    object-fit: contain;
    position: absolute;
    width: 100%;
    z-index: 5;
  }
  & > img:nth-child(3) {
    bottom: ${({ type }) => {
      if (type === 'hat') return '-200%';
      if (type === 'top') return '-110%';
      return '-7%';
    }};

    left: ${({ type }) => {
      if (type === 'hat') return '-113%';
      if (type === 'top') return '-100%';
      return '-60%';
    }};
    height: ${({ type }) => {
      if (type === 'hat') return '330%';
      if (type === 'top') return '300%';
      return '220%';
    }};
    object-fit: contain;
    position: absolute;
    width: ${({ type }) => {
      if (type === 'hat') return '330%';
      if (type === 'top') return '300%';
      return '220%';
    }};
  }

  & > p {
    color: ${classic.token?.colorTextSecondary};
    bottom: 3px;
    font-family: 'MaliBold', sans-serif;
    font-size: 0.6rem;
    font-weight: bold;
    height: 20%;
    left: 0;
    position: absolute;
    text-align: center;
    width: 100%;
    z-index: 6;
  }
`;
const SDivInfo = styled.div`
  flex: 0 0 55%;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  width: 100%;
`;
const SDivInfoItem = styled.div`
  align-items: center;
  color: #0f030c;
  display: flex;
  justify-content: space-between;
  width: 100%;
  & > span {
    font-family: 'MaliBold', sans-serif;
    font-size: 0.85rem;
  }
`;
