import React, { useState, useEffect } from 'react';
import styled from 'styled-components';

import { SDot, SDots, SArrowLeft, SArrowRight } from '~/utils/styles';

type IProps = {
  autoPlay: boolean;
  autoPlayTime: number;
  items: React.JSX.Element[];
};

function Slider({ items, autoPlay, autoPlayTime }: IProps) {
  const [slide, setSlide] = useState(0);
  const [touchPosition, setTouchPosition] = useState<number | null>(null);

  useEffect(() => {
    if (!autoPlay) return;
    const interval = setInterval(() => {
      changeSlide(1);
    }, autoPlayTime);

    return () => {
      clearInterval(interval);
    };
  }, [items.length, slide]);

  // Prev si direction = -1, next si direction = 1
  function changeSlide(direction = 1) {
    let slideNumber = 0;
    slideNumber =
      slide + direction < 0
        ? items.length - 1
        : (slide + direction) % items.length;
    setSlide(slideNumber);
  }

  function goToSlide(number: number) {
    setSlide(number % items.length);
  }

  function handleTouchStart(e: React.TouchEvent) {
    const touchDown = e.touches[0].clientX;
    setTouchPosition(touchDown);
  }

  function handleTouchMove(e: React.TouchEvent) {
    if (touchPosition === null) {
      return;
    }
    const currentPosition = e.touches[0].clientX;
    const direction = touchPosition - currentPosition;
    if (direction > 10) {
      changeSlide(1);
    }
    if (direction < -10) {
      changeSlide(-1);
    }
    setTouchPosition(null);
  }

  return (
    <Container
      className="slider"
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
    >
      <SDivContent>
        <SDivImg style={{ transform: `translateX(-${slide * 100}%)` }}>
          {items.map((itemSlide, index) => (
            <SDivSlide key={index}>{itemSlide}</SDivSlide>
          ))}
        </SDivImg>
      </SDivContent>
      <SArrowLeft onClick={() => changeSlide(-1)} />
      <SArrowRight onClick={() => changeSlide(1)} />
      <SDots>
        {items.map((_, index) => (
          <SDot
            $current={(index === slide).toString()}
            key={index}
            onClick={() => {
              goToSlide(index);
            }}
          />
        ))}
      </SDots>
    </Container>
  );
}

export default Slider;

const Container = styled.div`
  height: 650px;
  margin: 0 auto;
  position: relative;
  padding: 0 64px; /* arrows' width +10 */
  width: 100%;
  z-index: 5;
  @media screen and (max-width: 1192px) {
    width: 100%;
  }
  @media screen and (max-width: 992px) {
    height: 450px;
    width: 100%;
  }
  @media screen and (max-width: 767px) {
    padding: 0 54px; /* arrows' width +10 */
    width: 100%;
  }
  @media screen and (max-width: 667px) {
    padding: 0 33px; /* arrows' width +10 */
    width: 100%;
  }
  @media screen and (max-width: 500px) {
    height: 350px;
    width: 100%;
  }
  @media screen and (max-width: 667px) and ((orientation: landscape)) {
    height: 400px;
    padding: 0 33px; /* arrows' width +10 */
    width: 80%;
  }
  @media screen and (max-height: 280px) {
    height: 275px;
  }
`;

const SDivContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  overflow: hidden;
  width: 100%;
`;
/* Slider list */
const SDivImg = styled.div`
  display: flex;
  height: 100%;
  transition: transform 0.5s ease-in-out;
  width: 100%;
`;

const SDivSlide = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1 0 100%;
  justify-content: center;
  position: relative;

  & > div.success {
    display: grid;
    row-gap: 15px;
    column-gap: 20px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    grid-auto-flow: column;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 880px) {
      display: grid;
      row-gap: 15px;
      column-gap: 20px;
      overflow-y: auto;
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      grid-auto-flow: column;
      height: 100%;
      width: 100%;
    }
  }

  & > div.friends {
    display: grid;
    row-gap: 15px;
    column-gap: 20px;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-auto-flow: column;
    height: 100%;
    width: 100%;
    @media screen and (max-width: 992px) {
      column-gap: 0;
    }
    @media screen and (max-width: 800px) {
      grid-template-columns: 1fr;
      grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
      overflow-y: auto;
      row-gap: 10px;
      width: 100%;
    }
  }
`;
