import { gql } from '@apollo/client';

export const GQL_LOGIN = gql`
  query ($email: String!, $password: String!) {
    login(email: $email, password: $password) {
      __typename
      ... on Tokens {
        access_token
        refresh_token
      }
      ... on CustomError {
        error
        message
      }
    }
  }
`;

export const GQL_REFRESH = gql`
  query {
    refresh {
      __typename
      ... on Tokens {
        access_token
        refresh_token
      }
      ... on CustomError {
        error
        message
      }
    }
  }
`;

export const GQL_LOGOUT = gql`
  query {
    logout
  }
`;
