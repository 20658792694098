import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLingui } from '@lingui/react';
import { IProgression } from '~/pages/dashboard/types';
import { SDivListItems } from '~/utils/styles';
import { Tooltip } from 'antd';
import Title from '~/utils/components/ui/Title';
import ProgressSm from '~/utils/components/ui/ProgressSm';
import ImgIconLg from '~/utils/components/ui/ImgIconLg';
import tJeton from '@assets/image/icons/trophees/t-jeton.png';
import tBallon from '@assets/image/icons/trophees/t-ballon.png';
import tPikaplant from '@assets/image/icons/trophees/t-pikaplant.png';
import tSkate from '@assets/image/icons/trophees/t-skate.png';
import tGlace from '@assets/image/icons/trophees/t-glace.png';
import tBrochure from '@assets/image/icons/trophees/t-brochure.png';
import tBobine from '@assets/image/icons/trophees/t-bobine.png';
import tSolidarity from '@assets/image/icons/trophees/t-solidarity.png';
import { useAuth } from '~/helpers/store/auth';

type IProps = {
  arcProgression: any | null;
};

function Progression({ arcProgression }: IProps) {
  const { i18n } = useLingui();
  const [progressArr, setProgressArr] = useState<IProgression[]>([]);
  const { payload } = useAuth();
  useEffect(() => {
    function getProgressByArc() {
      if (arcProgression) {
        const _progressArr: IProgression[] = [
          {
            image: tJeton,
            progress: Math.round(arcProgression.arc1?.progress || 0),
            name: 'Arc 1',
          },
          {
            image: tPikaplant,
            progress: Math.round(arcProgression.arc2?.progress || 0),
            name: 'Arc 2',
          },
          {
            image: tSkate,
            progress: Math.round(arcProgression.arc3?.progress || 0),
            name: 'Arc 3',
          },
          {
            image: tGlace,
            progress: Math.round(arcProgression.arc4?.progress || 0),
            name: 'Arc 4',
          },
          {
            image: tBrochure,
            progress: Math.round(arcProgression.arc5?.progress || 0),
            name: 'Arc 5',
          },
          {
            image: tBobine,
            progress: Math.round(arcProgression.arc6?.progress || 0),
            name: 'Arc 6',
          },
          {
            image: tBallon,
            progress: Math.round(arcProgression.arc7?.progress || 0),
            name: 'Arc 7',
          },
          {
            image: tSolidarity,
            progress: Math.round(arcProgression.arc8?.progress || 0),
            name: 'Arc 8',
          },
        ];
        setProgressArr(_progressArr);
      }
    }
    getProgressByArc();
  }, [arcProgression]);

  if (!payload) return null;

  return (
    <Container id="progression">
      <Title withbackground text={i18n._('menu.progression')} />
      <SDivListItems>
        {progressArr.map((item, index) => (
          <Tooltip
            placement="bottom"
            key={item.image + index}
            title={item.name}
          >
            <div>
              <ImgIconLg bg={item.image} />
              <ProgressSm color="primary" value={item.progress} />
            </div>
          </Tooltip>
        ))}
      </SDivListItems>
    </Container>
  );
}

export default Progression;

const Container = styled.div`
  align-items: center;
  background: #0f030c;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 35px 40px 60px 40px;
  position: relative;
  width: 100%;
  transform-style: preserve-3d;
  @media screen and (max-width: 280px) {
    padding: 35px 20px 60px 20px;
  }
`;
