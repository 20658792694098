// @ts-nocheck
/* eslint-disable */

import { AllTypesProps, ReturnTypes, Ops } from './const';


export const HOST="Specify host"


export const HEADERS = {}
export const apiSubscription = (options: chainOptions) => (query: string) => {
  try {
    const queryString = options[0] + '?query=' + encodeURIComponent(query);
    const wsString = queryString.replace('http', 'ws');
    const host = (options.length > 1 && options[1]?.websocket?.[0]) || wsString;
    const webSocketOptions = options[1]?.websocket || [host];
    const ws = new WebSocket(...webSocketOptions);
    return {
      ws,
      on: (e: (args: any) => void) => {
        ws.onmessage = (event: any) => {
          if (event.data) {
            const parsed = JSON.parse(event.data);
            const data = parsed.data;
            return e(data);
          }
        };
      },
      off: (e: (args: any) => void) => {
        ws.onclose = e;
      },
      error: (e: (args: any) => void) => {
        ws.onerror = e;
      },
      open: (e: () => void) => {
        ws.onopen = e;
      },
    };
  } catch {
    throw new Error('No websockets implemented');
  }
};
const handleFetchResponse = (response: Response): Promise<GraphQLResponse> => {
  if (!response.ok) {
    return new Promise((_, reject) => {
      response
        .text()
        .then((text) => {
          try {
            reject(JSON.parse(text));
          } catch (err) {
            reject(text);
          }
        })
        .catch(reject);
    });
  }
  return response.json() as Promise<GraphQLResponse>;
};

export const apiFetch =
  (options: fetchOptions) =>
  (query: string, variables: Record<string, unknown> = {}) => {
    const fetchOptions = options[1] || {};
    if (fetchOptions.method && fetchOptions.method === 'GET') {
      return fetch(`${options[0]}?query=${encodeURIComponent(query)}`, fetchOptions)
        .then(handleFetchResponse)
        .then((response: GraphQLResponse) => {
          if (response.errors) {
            throw new GraphQLError(response);
          }
          return response.data;
        });
    }
    return fetch(`${options[0]}`, {
      body: JSON.stringify({ query, variables }),
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      ...fetchOptions,
    })
      .then(handleFetchResponse)
      .then((response: GraphQLResponse) => {
        if (response.errors) {
          throw new GraphQLError(response);
        }
        return response.data;
      });
  };

export const InternalsBuildQuery = ({
  ops,
  props,
  returns,
  options,
  scalars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  options?: OperationOptions;
  scalars?: ScalarDefinition;
}) => {
  const ibb = (
    k: string,
    o: InputValueType | VType,
    p = '',
    root = true,
    vars: Array<{ name: string; graphQLType: string }> = [],
  ): string => {
    const keyForPath = purifyGraphQLKey(k);
    const newPath = [p, keyForPath].join(SEPARATOR);
    if (!o) {
      return '';
    }
    if (typeof o === 'boolean' || typeof o === 'number') {
      return k;
    }
    if (typeof o === 'string') {
      return `${k} ${o}`;
    }
    if (Array.isArray(o)) {
      const args = InternalArgsBuilt({
        props,
        returns,
        ops,
        scalars,
        vars,
      })(o[0], newPath);
      return `${ibb(args ? `${k}(${args})` : k, o[1], p, false, vars)}`;
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(`${alias}:${operationName}`, operation, p, false, vars);
        })
        .join('\n');
    }
    const hasOperationName = root && options?.operationName ? ' ' + options.operationName : '';
    const keyForDirectives = o.__directives ?? '';
    const query = `{${Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map((e) => ibb(...e, [p, `field<>${keyForPath}`].join(SEPARATOR), false, vars))
      .join('\n')}}`;
    if (!root) {
      return `${k} ${keyForDirectives}${hasOperationName} ${query}`;
    }
    const varsString = vars.map((v) => `${v.name}: ${v.graphQLType}`).join(', ');
    return `${k} ${keyForDirectives}${hasOperationName}${varsString ? `(${varsString})` : ''} ${query}`;
  };
  return ibb;
};

export const Thunder =
  (fn: FetchFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: Record<string, unknown> }) =>
    fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
      ops?.variables,
    ).then((data) => {
      if (graphqlOptions?.scalars) {
        return decodeScalarsInResponse({
          response: data,
          initialOp: operation,
          initialZeusQuery: o as VType,
          returns: ReturnTypes,
          scalars: graphqlOptions.scalars,
          ops: Ops,
        });
      }
      return data;
    }) as Promise<InputType<GraphQLTypes[R], Z, SCLR>>;

export const Chain = (...options: chainOptions) => Thunder(apiFetch(options));

export const SubscriptionThunder =
  (fn: SubscriptionFunction) =>
  <O extends keyof typeof Ops, SCLR extends ScalarDefinition, R extends keyof ValueTypes = GenericOperation<O>>(
    operation: O,
    graphqlOptions?: ThunderGraphQLOptions<SCLR>,
  ) =>
  <Z extends ValueTypes[R]>(o: Z | ValueTypes[R], ops?: OperationOptions & { variables?: ExtractVariables<Z> }) => {
    const returnedFunction = fn(
      Zeus(operation, o, {
        operationOptions: ops,
        scalars: graphqlOptions?.scalars,
      }),
    ) as SubscriptionToGraphQL<Z, GraphQLTypes[R], SCLR>;
    if (returnedFunction?.on && graphqlOptions?.scalars) {
      const wrapped = returnedFunction.on;
      returnedFunction.on = (fnToCall: (args: InputType<GraphQLTypes[R], Z, SCLR>) => void) =>
        wrapped((data: InputType<GraphQLTypes[R], Z, SCLR>) => {
          if (graphqlOptions?.scalars) {
            return fnToCall(
              decodeScalarsInResponse({
                response: data,
                initialOp: operation,
                initialZeusQuery: o as VType,
                returns: ReturnTypes,
                scalars: graphqlOptions.scalars,
                ops: Ops,
              }),
            );
          }
          return fnToCall(data);
        });
    }
    return returnedFunction;
  };

export const Subscription = (...options: chainOptions) => SubscriptionThunder(apiSubscription(options));
export const Zeus = <
  Z extends ValueTypes[R],
  O extends keyof typeof Ops,
  R extends keyof ValueTypes = GenericOperation<O>,
>(
  operation: O,
  o: Z | ValueTypes[R],
  ops?: {
    operationOptions?: OperationOptions;
    scalars?: ScalarDefinition;
  },
) =>
  InternalsBuildQuery({
    props: AllTypesProps,
    returns: ReturnTypes,
    ops: Ops,
    options: ops?.operationOptions,
    scalars: ops?.scalars,
  })(operation, o as VType);

export const ZeusSelect = <T>() => ((t: unknown) => t) as SelectionFunction<T>;

export const Selector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();

export const TypeFromSelector = <T extends keyof ValueTypes>(key: T) => key && ZeusSelect<ValueTypes[T]>();
export const Gql = Chain(HOST, {
  headers: {
    'Content-Type': 'application/json',
    ...HEADERS,
  },
});

export const ZeusScalars = ZeusSelect<ScalarCoders>();

export const decodeScalarsInResponse = <O extends Operations>({
  response,
  scalars,
  returns,
  ops,
  initialZeusQuery,
  initialOp,
}: {
  ops: O;
  response: any;
  returns: ReturnTypesType;
  scalars?: Record<string, ScalarResolver | undefined>;
  initialOp: keyof O;
  initialZeusQuery: InputValueType | VType;
}) => {
  if (!scalars) {
    return response;
  }
  const builder = PrepareScalarPaths({
    ops,
    returns,
  });

  const scalarPaths = builder(initialOp as string, ops[initialOp], initialZeusQuery);
  if (scalarPaths) {
    const r = traverseResponse({ scalarPaths, resolvers: scalars })(initialOp as string, response, [ops[initialOp]]);
    return r;
  }
  return response;
};

export const traverseResponse = ({
  resolvers,
  scalarPaths,
}: {
  scalarPaths: { [x: string]: `scalar.${string}` };
  resolvers: {
    [x: string]: ScalarResolver | undefined;
  };
}) => {
  const ibb = (k: string, o: InputValueType | VType, p: string[] = []): unknown => {
    if (Array.isArray(o)) {
      return o.map((eachO) => ibb(k, eachO, p));
    }
    if (o == null) {
      return o;
    }
    const scalarPathString = p.join(SEPARATOR);
    const currentScalarString = scalarPaths[scalarPathString];
    if (currentScalarString) {
      const currentDecoder = resolvers[currentScalarString.split('.')[1]]?.decode;
      if (currentDecoder) {
        return currentDecoder(o);
      }
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string' || !o) {
      return o;
    }
    const entries = Object.entries(o).map(([k, v]) => [k, ibb(k, v, [...p, purifyGraphQLKey(k)])] as const);
    const objectFromEntries = entries.reduce<Record<string, unknown>>((a, [k, v]) => {
      a[k] = v;
      return a;
    }, {});
    return objectFromEntries;
  };
  return ibb;
};

export type AllTypesPropsType = {
  [x: string]:
    | undefined
    | `scalar.${string}`
    | 'enum'
    | {
        [x: string]:
          | undefined
          | string
          | {
              [x: string]: string | undefined;
            };
      };
};

export type ReturnTypesType = {
  [x: string]:
    | {
        [x: string]: string | undefined;
      }
    | `scalar.${string}`
    | undefined;
};
export type InputValueType = {
  [x: string]: undefined | boolean | string | number | [any, undefined | boolean | InputValueType] | InputValueType;
};
export type VType =
  | undefined
  | boolean
  | string
  | number
  | [any, undefined | boolean | InputValueType]
  | InputValueType;

export type PlainType = boolean | number | string | null | undefined;
export type ZeusArgsType =
  | PlainType
  | {
      [x: string]: ZeusArgsType;
    }
  | Array<ZeusArgsType>;

export type Operations = Record<string, string>;

export type VariableDefinition = {
  [x: string]: unknown;
};

export const SEPARATOR = '|';

export type fetchOptions = Parameters<typeof fetch>;
type websocketOptions = typeof WebSocket extends new (...args: infer R) => WebSocket ? R : never;
export type chainOptions = [fetchOptions[0], fetchOptions[1] & { websocket?: websocketOptions }] | [fetchOptions[0]];
export type FetchFunction = (query: string, variables?: Record<string, unknown>) => Promise<any>;
export type SubscriptionFunction = (query: string) => any;
type NotUndefined<T> = T extends undefined ? never : T;
export type ResolverType<F> = NotUndefined<F extends [infer ARGS, any] ? ARGS : undefined>;

export type OperationOptions = {
  operationName?: string;
};

export type ScalarCoder = Record<string, (s: unknown) => string>;

export interface GraphQLResponse {
  data?: Record<string, any>;
  errors?: Array<{
    message: string;
  }>;
}
export class GraphQLError extends Error {
  constructor(public response: GraphQLResponse) {
    super('');
    console.error(response);
  }
  toString() {
    return 'GraphQL Response Error';
  }
}
export type GenericOperation<O> = O extends keyof typeof Ops ? typeof Ops[O] : never;
export type ThunderGraphQLOptions<SCLR extends ScalarDefinition> = {
  scalars?: SCLR | ScalarCoders;
};

const ExtractScalar = (mappedParts: string[], returns: ReturnTypesType): `scalar.${string}` | undefined => {
  if (mappedParts.length === 0) {
    return;
  }
  const oKey = mappedParts[0];
  const returnP1 = returns[oKey];
  if (typeof returnP1 === 'object') {
    const returnP2 = returnP1[mappedParts[1]];
    if (returnP2) {
      return ExtractScalar([returnP2, ...mappedParts.slice(2)], returns);
    }
    return undefined;
  }
  return returnP1 as `scalar.${string}` | undefined;
};

export const PrepareScalarPaths = ({ ops, returns }: { returns: ReturnTypesType; ops: Operations }) => {
  const ibb = (
    k: string,
    originalKey: string,
    o: InputValueType | VType,
    p: string[] = [],
    pOriginals: string[] = [],
    root = true,
  ): { [x: string]: `scalar.${string}` } | undefined => {
    if (!o) {
      return;
    }
    if (typeof o === 'boolean' || typeof o === 'number' || typeof o === 'string') {
      const extractionArray = [...pOriginals, originalKey];
      const isScalar = ExtractScalar(extractionArray, returns);
      if (isScalar?.startsWith('scalar')) {
        const partOfTree = {
          [[...p, k].join(SEPARATOR)]: isScalar,
        };
        return partOfTree;
      }
      return {};
    }
    if (Array.isArray(o)) {
      return ibb(k, k, o[1], p, pOriginals, false);
    }
    if (k === '__alias') {
      return Object.entries(o)
        .map(([alias, objectUnderAlias]) => {
          if (typeof objectUnderAlias !== 'object' || Array.isArray(objectUnderAlias)) {
            throw new Error(
              'Invalid alias it should be __alias:{ YOUR_ALIAS_NAME: { OPERATION_NAME: { ...selectors }}}',
            );
          }
          const operationName = Object.keys(objectUnderAlias)[0];
          const operation = objectUnderAlias[operationName];
          return ibb(alias, operationName, operation, p, pOriginals, false);
        })
        .reduce((a, b) => ({
          ...a,
          ...b,
        }));
    }
    const keyName = root ? ops[k] : k;
    return Object.entries(o)
      .filter(([k]) => k !== '__directives')
      .map(([k, v]) => {
        // Inline fragments shouldn't be added to the path as they aren't a field
        const isInlineFragment = originalKey.match(/^...\s*on/) != null;
        return ibb(
          k,
          k,
          v,
          isInlineFragment ? p : [...p, purifyGraphQLKey(keyName || k)],
          isInlineFragment ? pOriginals : [...pOriginals, purifyGraphQLKey(originalKey)],
          false,
        );
      })
      .reduce((a, b) => ({
        ...a,
        ...b,
      }));
  };
  return ibb;
};

export const purifyGraphQLKey = (k: string) => k.replace(/\([^)]*\)/g, '').replace(/^[^:]*\:/g, '');

const mapPart = (p: string) => {
  const [isArg, isField] = p.split('<>');
  if (isField) {
    return {
      v: isField,
      __type: 'field',
    } as const;
  }
  return {
    v: isArg,
    __type: 'arg',
  } as const;
};

type Part = ReturnType<typeof mapPart>;

export const ResolveFromPath = (props: AllTypesPropsType, returns: ReturnTypesType, ops: Operations) => {
  const ResolvePropsType = (mappedParts: Part[]) => {
    const oKey = ops[mappedParts[0].v];
    const propsP1 = oKey ? props[oKey] : props[mappedParts[0].v];
    if (propsP1 === 'enum' && mappedParts.length === 1) {
      return 'enum';
    }
    if (typeof propsP1 === 'string' && propsP1.startsWith('scalar.') && mappedParts.length === 1) {
      return propsP1;
    }
    if (typeof propsP1 === 'object') {
      if (mappedParts.length < 2) {
        return 'not';
      }
      const propsP2 = propsP1[mappedParts[1].v];
      if (typeof propsP2 === 'string') {
        return rpp(
          `${propsP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
      if (typeof propsP2 === 'object') {
        if (mappedParts.length < 3) {
          return 'not';
        }
        const propsP3 = propsP2[mappedParts[2].v];
        if (propsP3 && mappedParts[2].__type === 'arg') {
          return rpp(
            `${propsP3}${SEPARATOR}${mappedParts
              .slice(3)
              .map((mp) => mp.v)
              .join(SEPARATOR)}`,
          );
        }
      }
    }
  };
  const ResolveReturnType = (mappedParts: Part[]) => {
    if (mappedParts.length === 0) {
      return 'not';
    }
    const oKey = ops[mappedParts[0].v];
    const returnP1 = oKey ? returns[oKey] : returns[mappedParts[0].v];
    if (typeof returnP1 === 'object') {
      if (mappedParts.length < 2) return 'not';
      const returnP2 = returnP1[mappedParts[1].v];
      if (returnP2) {
        return rpp(
          `${returnP2}${SEPARATOR}${mappedParts
            .slice(2)
            .map((mp) => mp.v)
            .join(SEPARATOR)}`,
        );
      }
    }
  };
  const rpp = (path: string): 'enum' | 'not' | `scalar.${string}` => {
    const parts = path.split(SEPARATOR).filter((l) => l.length > 0);
    const mappedParts = parts.map(mapPart);
    const propsP1 = ResolvePropsType(mappedParts);
    if (propsP1) {
      return propsP1;
    }
    const returnP1 = ResolveReturnType(mappedParts);
    if (returnP1) {
      return returnP1;
    }
    return 'not';
  };
  return rpp;
};

export const InternalArgsBuilt = ({
  props,
  ops,
  returns,
  scalars,
  vars,
}: {
  props: AllTypesPropsType;
  returns: ReturnTypesType;
  ops: Operations;
  scalars?: ScalarDefinition;
  vars: Array<{ name: string; graphQLType: string }>;
}) => {
  const arb = (a: ZeusArgsType, p = '', root = true): string => {
    if (typeof a === 'string') {
      if (a.startsWith(START_VAR_NAME)) {
        const [varName, graphQLType] = a.replace(START_VAR_NAME, '$').split(GRAPHQL_TYPE_SEPARATOR);
        const v = vars.find((v) => v.name === varName);
        if (!v) {
          vars.push({
            name: varName,
            graphQLType,
          });
        } else {
          if (v.graphQLType !== graphQLType) {
            throw new Error(
              `Invalid variable exists with two different GraphQL Types, "${v.graphQLType}" and ${graphQLType}`,
            );
          }
        }
        return varName;
      }
    }
    const checkType = ResolveFromPath(props, returns, ops)(p);
    if (checkType.startsWith('scalar.')) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const [_, ...splittedScalar] = checkType.split('.');
      const scalarKey = splittedScalar.join('.');
      return (scalars?.[scalarKey]?.encode?.(a) as string) || JSON.stringify(a);
    }
    if (Array.isArray(a)) {
      return `[${a.map((arr) => arb(arr, p, false)).join(', ')}]`;
    }
    if (typeof a === 'string') {
      if (checkType === 'enum') {
        return a;
      }
      return `${JSON.stringify(a)}`;
    }
    if (typeof a === 'object') {
      if (a === null) {
        return `null`;
      }
      const returnedObjectString = Object.entries(a)
        .filter(([, v]) => typeof v !== 'undefined')
        .map(([k, v]) => `${k}: ${arb(v, [p, k].join(SEPARATOR), false)}`)
        .join(',\n');
      if (!root) {
        return `{${returnedObjectString}}`;
      }
      return returnedObjectString;
    }
    return `${a}`;
  };
  return arb;
};

export const resolverFor = <X, T extends keyof ResolverInputTypes, Z extends keyof ResolverInputTypes[T]>(
  type: T,
  field: Z,
  fn: (
    args: Required<ResolverInputTypes[T]>[Z] extends [infer Input, any] ? Input : any,
    source: any,
  ) => Z extends keyof ModelTypes[T] ? ModelTypes[T][Z] | Promise<ModelTypes[T][Z]> | X : never,
) => fn as (args?: any, source?: any) => ReturnType<typeof fn>;

export type UnwrapPromise<T> = T extends Promise<infer R> ? R : T;
export type ZeusState<T extends (...args: any[]) => Promise<any>> = NonNullable<UnwrapPromise<ReturnType<T>>>;
export type ZeusHook<
  T extends (...args: any[]) => Record<string, (...args: any[]) => Promise<any>>,
  N extends keyof ReturnType<T>,
> = ZeusState<ReturnType<T>[N]>;

export type WithTypeNameValue<T> = T & {
  __typename?: boolean;
  __directives?: string;
};
export type AliasType<T> = WithTypeNameValue<T> & {
  __alias?: Record<string, WithTypeNameValue<T>>;
};
type DeepAnify<T> = {
  [P in keyof T]?: any;
};
type IsPayLoad<T> = T extends [any, infer PayLoad] ? PayLoad : T;
export type ScalarDefinition = Record<string, ScalarResolver>;

type IsScalar<S, SCLR extends ScalarDefinition> = S extends 'scalar' & { name: infer T }
  ? T extends keyof SCLR
    ? SCLR[T]['decode'] extends (s: unknown) => unknown
      ? ReturnType<SCLR[T]['decode']>
      : unknown
    : unknown
  : S;
type IsArray<T, U, SCLR extends ScalarDefinition> = T extends Array<infer R>
  ? InputType<R, U, SCLR>[]
  : InputType<T, U, SCLR>;
type FlattenArray<T> = T extends Array<infer R> ? R : T;
type BaseZeusResolver = boolean | 1 | string | Variable<any, string>;

type IsInterfaced<SRC extends DeepAnify<DST>, DST, SCLR extends ScalarDefinition> = FlattenArray<SRC> extends
  | ZEUS_INTERFACES
  | ZEUS_UNIONS
  ? {
      [P in keyof SRC]: SRC[P] extends '__union' & infer R
        ? P extends keyof DST
          ? IsArray<R, '__typename' extends keyof DST ? DST[P] & { __typename: true } : DST[P], SCLR>
          : IsArray<R, '__typename' extends keyof DST ? { __typename: true } : never, SCLR>
        : never;
    }[keyof SRC] & {
      [P in keyof Omit<
        Pick<
          SRC,
          {
            [P in keyof DST]: SRC[P] extends '__union' & infer R ? never : P;
          }[keyof DST]
        >,
        '__typename'
      >]: IsPayLoad<DST[P]> extends BaseZeusResolver ? IsScalar<SRC[P], SCLR> : IsArray<SRC[P], DST[P], SCLR>;
    }
  : {
      [P in keyof Pick<SRC, keyof DST>]: IsPayLoad<DST[P]> extends BaseZeusResolver
        ? IsScalar<SRC[P], SCLR>
        : IsArray<SRC[P], DST[P], SCLR>;
    };

export type MapType<SRC, DST, SCLR extends ScalarDefinition> = SRC extends DeepAnify<DST>
  ? IsInterfaced<SRC, DST, SCLR>
  : never;
// eslint-disable-next-line @typescript-eslint/ban-types
export type InputType<SRC, DST, SCLR extends ScalarDefinition = {}> = IsPayLoad<DST> extends { __alias: infer R }
  ? {
      [P in keyof R]: MapType<SRC, R[P], SCLR>[keyof MapType<SRC, R[P], SCLR>];
    } & MapType<SRC, Omit<IsPayLoad<DST>, '__alias'>, SCLR>
  : MapType<SRC, IsPayLoad<DST>, SCLR>;
export type SubscriptionToGraphQL<Z, T, SCLR extends ScalarDefinition> = {
  ws: WebSocket;
  on: (fn: (args: InputType<T, Z, SCLR>) => void) => void;
  off: (fn: (e: { data?: InputType<T, Z, SCLR>; code?: number; reason?: string; message?: string }) => void) => void;
  error: (fn: (e: { data?: InputType<T, Z, SCLR>; errors?: string[] }) => void) => void;
  open: () => void;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type FromSelector<SELECTOR, NAME extends keyof GraphQLTypes, SCLR extends ScalarDefinition = {}> = InputType<
  GraphQLTypes[NAME],
  SELECTOR,
  SCLR
>;

export type ScalarResolver = {
  encode?: (s: unknown) => string;
  decode?: (s: unknown) => unknown;
};

export type SelectionFunction<V> = <T>(t: T | V) => T;

type BuiltInVariableTypes = {
  ['String']: string;
  ['Int']: number;
  ['Float']: number;
  ['ID']: unknown;
  ['Boolean']: boolean;
};
type AllVariableTypes = keyof BuiltInVariableTypes | keyof ZEUS_VARIABLES;
type VariableRequired<T extends string> = `${T}!` | T | `[${T}]` | `[${T}]!` | `[${T}!]` | `[${T}!]!`;
type VR<T extends string> = VariableRequired<VariableRequired<T>>;

export type GraphQLVariableType = VR<AllVariableTypes>;

type ExtractVariableTypeString<T extends string> = T extends VR<infer R1>
  ? R1 extends VR<infer R2>
    ? R2 extends VR<infer R3>
      ? R3 extends VR<infer R4>
        ? R4 extends VR<infer R5>
          ? R5
          : R4
        : R3
      : R2
    : R1
  : T;

type DecomposeType<T, Type> = T extends `[${infer R}]`
  ? Array<DecomposeType<R, Type>> | undefined
  : T extends `${infer R}!`
  ? NonNullable<DecomposeType<R, Type>>
  : Type | undefined;

type ExtractTypeFromGraphQLType<T extends string> = T extends keyof ZEUS_VARIABLES
  ? ZEUS_VARIABLES[T]
  : T extends keyof BuiltInVariableTypes
  ? BuiltInVariableTypes[T]
  : any;

export type GetVariableType<T extends string> = DecomposeType<
  T,
  ExtractTypeFromGraphQLType<ExtractVariableTypeString<T>>
>;

type UndefinedKeys<T> = {
  [K in keyof T]-?: T[K] extends NonNullable<T[K]> ? never : K;
}[keyof T];

type WithNullableKeys<T> = Pick<T, UndefinedKeys<T>>;
type WithNonNullableKeys<T> = Omit<T, UndefinedKeys<T>>;

type OptionalKeys<T> = {
  [P in keyof T]?: T[P];
};

export type WithOptionalNullables<T> = OptionalKeys<WithNullableKeys<T>> & WithNonNullableKeys<T>;

export type Variable<T extends GraphQLVariableType, Name extends string> = {
  ' __zeus_name': Name;
  ' __zeus_type': T;
};

export type ExtractVariables<Query> = Query extends Variable<infer VType, infer VName>
  ? { [key in VName]: GetVariableType<VType> }
  : Query extends [infer Inputs, infer Outputs]
  ? ExtractVariables<Inputs> & ExtractVariables<Outputs>
  : Query extends string | number | boolean
  ? // eslint-disable-next-line @typescript-eslint/ban-types
    {}
  : UnionToIntersection<{ [K in keyof Query]: WithOptionalNullables<ExtractVariables<Query[K]>> }[keyof Query]>;

type UnionToIntersection<U> = (U extends any ? (k: U) => void : never) extends (k: infer I) => void ? I : never;

export const START_VAR_NAME = `$ZEUS_VAR`;
export const GRAPHQL_TYPE_SEPARATOR = `__$GRAPHQL__`;

export const $ = <Type extends GraphQLVariableType, Name extends string>(name: Name, graphqlType: Type) => {
  return (START_VAR_NAME + name + GRAPHQL_TYPE_SEPARATOR + graphqlType) as unknown as Variable<Type, Name>;
};
type ZEUS_INTERFACES = never
export type ScalarCoders = {
	DateTime?: ScalarResolver;
}
type ZEUS_UNIONS = GraphQLTypes["AuthType"]

export type ValueTypes = {
    ["Health"]: AliasType<{
	env?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Permissions"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	roles?:ValueTypes["Roles"],
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:unknown;
	["ClothingUnlock"]: AliasType<{
	total?:boolean | `@${string}`,
	normal?:boolean | `@${string}`,
	rare?:boolean | `@${string}`,
	epic?:boolean | `@${string}`,
	legendary?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Clothing"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	rarity?:boolean | `@${string}`,
	isDefault?:boolean | `@${string}`,
	avatarEntityHat?:ValueTypes["Avatar"],
	avatarEntityTop?:ValueTypes["Avatar"],
	avatarEntityBottom?:ValueTypes["Avatar"],
		__typename?: boolean | `@${string}`
}>;
	["ClothingType"]:ClothingType;
	["ClothingRarity"]:ClothingRarity;
	["Avatar"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	face?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	hat?:ValueTypes["Clothing"],
	hatId?:boolean | `@${string}`,
	top?:ValueTypes["Clothing"],
	topId?:boolean | `@${string}`,
	bottom?:ValueTypes["Clothing"],
	bottomId?:boolean | `@${string}`,
	users?:ValueTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["Progress"]: AliasType<{
	progress?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ArcProgress"]: AliasType<{
	arc1?:ValueTypes["Progress"],
	arc2?:ValueTypes["Progress"],
	arc3?:ValueTypes["Progress"],
	arc4?:ValueTypes["Progress"],
	arc5?:ValueTypes["Progress"],
	arc6?:ValueTypes["Progress"],
	arc7?:ValueTypes["Progress"],
	arc8?:ValueTypes["Progress"],
		__typename?: boolean | `@${string}`
}>;
	["Tracking"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	idQuest?:boolean | `@${string}`,
	idMainQuest?:boolean | `@${string}`,
	queteAnnexe?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	users?:ValueTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["Location"]:Location;
	["TrackingProgress"]: AliasType<{
	arcProgress?:ValueTypes["ArcProgress"],
	totalProgress?:boolean | `@${string}`,
	queteAnnexe?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ReputationProgress"]: AliasType<{
	canopee?:boolean | `@${string}`,
	buisnessDistrict?:boolean | `@${string}`,
	lagon?:boolean | `@${string}`,
	residentialDistrict?:boolean | `@${string}`,
	hub?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuccessList"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	Success?:ValueTypes["Success"],
		__typename?: boolean | `@${string}`
}>;
	["Success"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	users?:ValueTypes["Users"],
	successList?:ValueTypes["SuccessList"],
	successListId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetAllSuccess"]: AliasType<{
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	count?:boolean | `@${string}`,
	unlock?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Users"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	azureAdOpenId?:boolean | `@${string}`,
	role?:ValueTypes["Roles"],
	roleId?:boolean | `@${string}`,
	avatar?:ValueTypes["Avatar"],
	avatarId?:boolean | `@${string}`,
	tracking?:ValueTypes["Tracking"],
	trackingId?:boolean | `@${string}`,
	successUnlock?:ValueTypes["Success"],
		__typename?: boolean | `@${string}`
}>;
	["Roles"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:ValueTypes["Permissions"],
	users?:ValueTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["Configs"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mail"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	to?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
	getHealthGql?:ValueTypes["Health"],
getRole?: [{	id: number | Variable<any, string>},ValueTypes["Roles"]],
	getRoles?:ValueTypes["Roles"],
getPermission?: [{	id: number | Variable<any, string>},ValueTypes["Permissions"]],
	getPermissions?:ValueTypes["Permissions"],
getConfig?: [{	id: number | Variable<any, string>},ValueTypes["Configs"]],
	getConfigs?:ValueTypes["Configs"],
getMail?: [{	id: number | Variable<any, string>},ValueTypes["Mail"]],
	getMails?:ValueTypes["Mail"],
	sendMail?:boolean | `@${string}`,
login?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>},ValueTypes["AuthType"]],
register?: [{	email: string | Variable<any, string>,	password: string | Variable<any, string>,	firstName: string | Variable<any, string>,	lastName: string | Variable<any, string>},ValueTypes["AuthType"]],
	refresh?:ValueTypes["AuthType"],
	logout?:boolean | `@${string}`,
getUser?: [{	email: string | Variable<any, string>,	firstName?: string | undefined | null | Variable<any, string>,	lastName?: string | undefined | null | Variable<any, string>,	azureAdOpenId?: string | undefined | null | Variable<any, string>},ValueTypes["Users"]],
	getUsers?:ValueTypes["Users"],
getAvatar?: [{	email: string | Variable<any, string>},ValueTypes["Avatar"]],
getAllSuccessUnlock?: [{	email: string | Variable<any, string>},ValueTypes["Success"]],
getAllSuccess?: [{	email: string | Variable<any, string>},ValueTypes["GetAllSuccess"]],
getTracking?: [{	email: string | Variable<any, string>},ValueTypes["Tracking"]],
getReputation?: [{	email: string | Variable<any, string>},ValueTypes["ReputationProgress"]],
getArcsProgress?: [{	email: string | Variable<any, string>},ValueTypes["TrackingProgress"]],
getClothingStats?: [{	email: string | Variable<any, string>},ValueTypes["ClothingUnlock"]],
		__typename?: boolean | `@${string}`
}>;
	["AuthType"]: AliasType<{		["...on Tokens"] : ValueTypes["Tokens"],
		["...on CustomError"] : ValueTypes["CustomError"]
		__typename?: boolean | `@${string}`
}>;
	["Tokens"]: AliasType<{
	access_token?:boolean | `@${string}`,
	refresh_token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomError"]: AliasType<{
	error?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EError"]:EError;
	["Mutation"]: AliasType<{
updateAvatar?: [{	email: string | Variable<any, string>,	face: string | Variable<any, string>,	color: string | Variable<any, string>,	hatName: string | Variable<any, string>,	topName: string | Variable<any, string>,	bottomName: string | Variable<any, string>},boolean | `@${string}`],
resetAllSuccess?: [{	email: string | Variable<any, string>},boolean | `@${string}`],
incrementCountSuccess?: [{	email: string | Variable<any, string>,	code: string | Variable<any, string>},boolean | `@${string}`],
updateCountSuccess?: [{	email: string | Variable<any, string>,	code: string | Variable<any, string>,	count: number | Variable<any, string>},boolean | `@${string}`],
unLockSuccess?: [{	email: string | Variable<any, string>,	code: string | Variable<any, string>},boolean | `@${string}`],
setScore?: [{	email: string | Variable<any, string>,	score: number | Variable<any, string>,	name: string | Variable<any, string>,	idQuest: string | Variable<any, string>},boolean | `@${string}`],
updateTracking?: [{	email: string | Variable<any, string>,	idQuest: string | Variable<any, string>,	idMainQuest: string | Variable<any, string>,	queteAnnexe: Array<boolean> | Variable<any, string>,	queteSecondaire: Array<boolean> | Variable<any, string>,	location: number | Variable<any, string>},boolean | `@${string}`],
popup?: [{	email: string | Variable<any, string>,	ads: string | Variable<any, string>,	sendMail: boolean | Variable<any, string>},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>
  }

export type ResolverInputTypes = {
    ["Health"]: AliasType<{
	env?:boolean | `@${string}`,
	time?:boolean | `@${string}`,
	status?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Permissions"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	roles?:ResolverInputTypes["Roles"],
		__typename?: boolean | `@${string}`
}>;
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:unknown;
	["ClothingUnlock"]: AliasType<{
	total?:boolean | `@${string}`,
	normal?:boolean | `@${string}`,
	rare?:boolean | `@${string}`,
	epic?:boolean | `@${string}`,
	legendary?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Clothing"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	type?:boolean | `@${string}`,
	rarity?:boolean | `@${string}`,
	isDefault?:boolean | `@${string}`,
	avatarEntityHat?:ResolverInputTypes["Avatar"],
	avatarEntityTop?:ResolverInputTypes["Avatar"],
	avatarEntityBottom?:ResolverInputTypes["Avatar"],
		__typename?: boolean | `@${string}`
}>;
	["ClothingType"]:ClothingType;
	["ClothingRarity"]:ClothingRarity;
	["Avatar"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	face?:boolean | `@${string}`,
	color?:boolean | `@${string}`,
	hat?:ResolverInputTypes["Clothing"],
	hatId?:boolean | `@${string}`,
	top?:ResolverInputTypes["Clothing"],
	topId?:boolean | `@${string}`,
	bottom?:ResolverInputTypes["Clothing"],
	bottomId?:boolean | `@${string}`,
	users?:ResolverInputTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["Progress"]: AliasType<{
	progress?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ArcProgress"]: AliasType<{
	arc1?:ResolverInputTypes["Progress"],
	arc2?:ResolverInputTypes["Progress"],
	arc3?:ResolverInputTypes["Progress"],
	arc4?:ResolverInputTypes["Progress"],
	arc5?:ResolverInputTypes["Progress"],
	arc6?:ResolverInputTypes["Progress"],
	arc7?:ResolverInputTypes["Progress"],
	arc8?:ResolverInputTypes["Progress"],
		__typename?: boolean | `@${string}`
}>;
	["Tracking"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	idQuest?:boolean | `@${string}`,
	idMainQuest?:boolean | `@${string}`,
	queteAnnexe?:boolean | `@${string}`,
	location?:boolean | `@${string}`,
	users?:ResolverInputTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["Location"]:Location;
	["TrackingProgress"]: AliasType<{
	arcProgress?:ResolverInputTypes["ArcProgress"],
	totalProgress?:boolean | `@${string}`,
	queteAnnexe?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["ReputationProgress"]: AliasType<{
	canopee?:boolean | `@${string}`,
	buisnessDistrict?:boolean | `@${string}`,
	lagon?:boolean | `@${string}`,
	residentialDistrict?:boolean | `@${string}`,
	hub?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["SuccessList"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	Success?:ResolverInputTypes["Success"],
		__typename?: boolean | `@${string}`
}>;
	["Success"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	users?:ResolverInputTypes["Users"],
	successList?:ResolverInputTypes["SuccessList"],
	successListId?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["GetAllSuccess"]: AliasType<{
	name?:boolean | `@${string}`,
	description?:boolean | `@${string}`,
	image?:boolean | `@${string}`,
	code?:boolean | `@${string}`,
	count?:boolean | `@${string}`,
	unlock?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Users"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	email?:boolean | `@${string}`,
	password?:boolean | `@${string}`,
	refreshToken?:boolean | `@${string}`,
	firstName?:boolean | `@${string}`,
	lastName?:boolean | `@${string}`,
	azureAdOpenId?:boolean | `@${string}`,
	role?:ResolverInputTypes["Roles"],
	roleId?:boolean | `@${string}`,
	avatar?:ResolverInputTypes["Avatar"],
	avatarId?:boolean | `@${string}`,
	tracking?:ResolverInputTypes["Tracking"],
	trackingId?:boolean | `@${string}`,
	successUnlock?:ResolverInputTypes["Success"],
		__typename?: boolean | `@${string}`
}>;
	["Roles"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	name?:boolean | `@${string}`,
	permissions?:ResolverInputTypes["Permissions"],
	users?:ResolverInputTypes["Users"],
		__typename?: boolean | `@${string}`
}>;
	["Configs"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	value?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Mail"]: AliasType<{
	id?:boolean | `@${string}`,
	uuid?:boolean | `@${string}`,
	createdAt?:boolean | `@${string}`,
	updatedAt?:boolean | `@${string}`,
	key?:boolean | `@${string}`,
	to?:boolean | `@${string}`,
	data?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["Query"]: AliasType<{
	getHealthGql?:ResolverInputTypes["Health"],
getRole?: [{	id: number},ResolverInputTypes["Roles"]],
	getRoles?:ResolverInputTypes["Roles"],
getPermission?: [{	id: number},ResolverInputTypes["Permissions"]],
	getPermissions?:ResolverInputTypes["Permissions"],
getConfig?: [{	id: number},ResolverInputTypes["Configs"]],
	getConfigs?:ResolverInputTypes["Configs"],
getMail?: [{	id: number},ResolverInputTypes["Mail"]],
	getMails?:ResolverInputTypes["Mail"],
	sendMail?:boolean | `@${string}`,
login?: [{	email: string,	password: string},ResolverInputTypes["AuthType"]],
register?: [{	email: string,	password: string,	firstName: string,	lastName: string},ResolverInputTypes["AuthType"]],
	refresh?:ResolverInputTypes["AuthType"],
	logout?:boolean | `@${string}`,
getUser?: [{	email: string,	firstName?: string | undefined | null,	lastName?: string | undefined | null,	azureAdOpenId?: string | undefined | null},ResolverInputTypes["Users"]],
	getUsers?:ResolverInputTypes["Users"],
getAvatar?: [{	email: string},ResolverInputTypes["Avatar"]],
getAllSuccessUnlock?: [{	email: string},ResolverInputTypes["Success"]],
getAllSuccess?: [{	email: string},ResolverInputTypes["GetAllSuccess"]],
getTracking?: [{	email: string},ResolverInputTypes["Tracking"]],
getReputation?: [{	email: string},ResolverInputTypes["ReputationProgress"]],
getArcsProgress?: [{	email: string},ResolverInputTypes["TrackingProgress"]],
getClothingStats?: [{	email: string},ResolverInputTypes["ClothingUnlock"]],
		__typename?: boolean | `@${string}`
}>;
	["AuthType"]: AliasType<{
	Tokens?:ResolverInputTypes["Tokens"],
	CustomError?:ResolverInputTypes["CustomError"],
		__typename?: boolean | `@${string}`
}>;
	["Tokens"]: AliasType<{
	access_token?:boolean | `@${string}`,
	refresh_token?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["CustomError"]: AliasType<{
	error?:boolean | `@${string}`,
	message?:boolean | `@${string}`,
		__typename?: boolean | `@${string}`
}>;
	["EError"]:EError;
	["Mutation"]: AliasType<{
updateAvatar?: [{	email: string,	face: string,	color: string,	hatName: string,	topName: string,	bottomName: string},boolean | `@${string}`],
resetAllSuccess?: [{	email: string},boolean | `@${string}`],
incrementCountSuccess?: [{	email: string,	code: string},boolean | `@${string}`],
updateCountSuccess?: [{	email: string,	code: string,	count: number},boolean | `@${string}`],
unLockSuccess?: [{	email: string,	code: string},boolean | `@${string}`],
setScore?: [{	email: string,	score: number,	name: string,	idQuest: string},boolean | `@${string}`],
updateTracking?: [{	email: string,	idQuest: string,	idMainQuest: string,	queteAnnexe: Array<boolean>,	queteSecondaire: Array<boolean>,	location: number},boolean | `@${string}`],
popup?: [{	email: string,	ads: string,	sendMail: boolean},boolean | `@${string}`],
		__typename?: boolean | `@${string}`
}>;
	["schema"]: AliasType<{
	query?:ResolverInputTypes["Query"],
	mutation?:ResolverInputTypes["Mutation"],
		__typename?: boolean | `@${string}`
}>
  }

export type ModelTypes = {
    ["Health"]: {
		env: string,
	time: string,
	status: string
};
	["Permissions"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	roles: Array<ModelTypes["Roles"]>
};
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]:any;
	["ClothingUnlock"]: {
		total: number,
	normal: number,
	rare: number,
	epic: number,
	legendary: number
};
	["Clothing"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	description: string,
	image: string,
	type: ModelTypes["ClothingType"],
	rarity: ModelTypes["ClothingRarity"],
	isDefault: boolean,
	avatarEntityHat: Array<ModelTypes["Avatar"]>,
	avatarEntityTop: Array<ModelTypes["Avatar"]>,
	avatarEntityBottom: Array<ModelTypes["Avatar"]>
};
	["ClothingType"]:ClothingType;
	["ClothingRarity"]:ClothingRarity;
	["Avatar"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	face: string,
	color: string,
	hat: ModelTypes["Clothing"],
	hatId: string,
	top: ModelTypes["Clothing"],
	topId: string,
	bottom: ModelTypes["Clothing"],
	bottomId: string,
	users: Array<ModelTypes["Users"]>
};
	["Progress"]: {
		progress: number
};
	["ArcProgress"]: {
		arc1: ModelTypes["Progress"],
	arc2: ModelTypes["Progress"],
	arc3: ModelTypes["Progress"],
	arc4: ModelTypes["Progress"],
	arc5: ModelTypes["Progress"],
	arc6: ModelTypes["Progress"],
	arc7: ModelTypes["Progress"],
	arc8: ModelTypes["Progress"]
};
	["Tracking"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	idQuest: string,
	idMainQuest: string,
	queteAnnexe: Array<boolean>,
	location: ModelTypes["Location"],
	users: Array<ModelTypes["Users"]>
};
	["Location"]:Location;
	["TrackingProgress"]: {
		arcProgress: ModelTypes["ArcProgress"],
	totalProgress: number,
	queteAnnexe: Array<boolean>
};
	["ReputationProgress"]: {
		canopee: number,
	buisnessDistrict: number,
	lagon: number,
	residentialDistrict: number,
	hub: number
};
	["SuccessList"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	description: string,
	image: string,
	code: string,
	Success: Array<ModelTypes["Success"]>
};
	["Success"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	users: Array<ModelTypes["Users"]>,
	successList: ModelTypes["SuccessList"],
	successListId: number
};
	["GetAllSuccess"]: {
		name: string,
	description: string,
	image: string,
	code: string,
	count?: number | undefined,
	unlock?: ModelTypes["DateTime"] | undefined
};
	["Users"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	email: string,
	password?: string | undefined,
	refreshToken?: string | undefined,
	firstName: string,
	lastName: string,
	azureAdOpenId?: string | undefined,
	role: ModelTypes["Roles"],
	roleId: number,
	avatar: ModelTypes["Avatar"],
	avatarId: number,
	tracking: ModelTypes["Tracking"],
	trackingId: number,
	successUnlock: Array<ModelTypes["Success"]>
};
	["Roles"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	name: string,
	permissions: Array<ModelTypes["Permissions"]>,
	users: Array<ModelTypes["Users"]>
};
	["Configs"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	key: string,
	value: string
};
	["Mail"]: {
		id: number,
	uuid: string,
	createdAt: ModelTypes["DateTime"],
	updatedAt: ModelTypes["DateTime"],
	key: string,
	to: string,
	data: string
};
	["Query"]: {
		getHealthGql: ModelTypes["Health"],
	getRole: ModelTypes["Roles"],
	getRoles: Array<ModelTypes["Roles"]>,
	getPermission: ModelTypes["Permissions"],
	getPermissions: Array<ModelTypes["Permissions"]>,
	getConfig: ModelTypes["Configs"],
	getConfigs: Array<ModelTypes["Configs"]>,
	getMail: ModelTypes["Mail"],
	getMails: Array<ModelTypes["Mail"]>,
	sendMail: boolean,
	login: ModelTypes["AuthType"],
	register: ModelTypes["AuthType"],
	refresh: ModelTypes["AuthType"],
	logout: boolean,
	getUser: ModelTypes["Users"],
	getUsers: Array<ModelTypes["Users"]>,
	getAvatar: ModelTypes["Avatar"],
	getAllSuccessUnlock: Array<ModelTypes["Success"]>,
	getAllSuccess: Array<ModelTypes["GetAllSuccess"]>,
	getTracking: ModelTypes["Tracking"],
	getReputation: ModelTypes["ReputationProgress"],
	getArcsProgress: ModelTypes["TrackingProgress"],
	getClothingStats: ModelTypes["ClothingUnlock"]
};
	["AuthType"]:ModelTypes["Tokens"] | ModelTypes["CustomError"];
	["Tokens"]: {
		access_token: string,
	refresh_token: string
};
	["CustomError"]: {
		error: ModelTypes["EError"],
	message: string
};
	["EError"]:EError;
	["Mutation"]: {
		updateAvatar: boolean,
	resetAllSuccess: boolean,
	incrementCountSuccess: boolean,
	updateCountSuccess: boolean,
	unLockSuccess: boolean,
	setScore: boolean,
	updateTracking: boolean,
	popup: boolean
};
	["schema"]: {
	query?: ModelTypes["Query"] | undefined,
	mutation?: ModelTypes["Mutation"] | undefined
}
    }

export type GraphQLTypes = {
    // ------------------------------------------------------;
	// THIS FILE WAS AUTOMATICALLY GENERATED (DO NOT MODIFY);
	// ------------------------------------------------------;
	["Health"]: {
	__typename: "Health",
	env: string,
	time: string,
	status: string
};
	["Permissions"]: {
	__typename: "Permissions",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	roles: Array<GraphQLTypes["Roles"]>
};
	/** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
["DateTime"]: "scalar" & { name: "DateTime" };
	["ClothingUnlock"]: {
	__typename: "ClothingUnlock",
	total: number,
	normal: number,
	rare: number,
	epic: number,
	legendary: number
};
	["Clothing"]: {
	__typename: "Clothing",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	description: string,
	image: string,
	type: GraphQLTypes["ClothingType"],
	rarity: GraphQLTypes["ClothingRarity"],
	isDefault: boolean,
	avatarEntityHat: Array<GraphQLTypes["Avatar"]>,
	avatarEntityTop: Array<GraphQLTypes["Avatar"]>,
	avatarEntityBottom: Array<GraphQLTypes["Avatar"]>
};
	["ClothingType"]: ClothingType;
	["ClothingRarity"]: ClothingRarity;
	["Avatar"]: {
	__typename: "Avatar",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	face: string,
	color: string,
	hat: GraphQLTypes["Clothing"],
	hatId: string,
	top: GraphQLTypes["Clothing"],
	topId: string,
	bottom: GraphQLTypes["Clothing"],
	bottomId: string,
	users: Array<GraphQLTypes["Users"]>
};
	["Progress"]: {
	__typename: "Progress",
	progress: number
};
	["ArcProgress"]: {
	__typename: "ArcProgress",
	arc1: GraphQLTypes["Progress"],
	arc2: GraphQLTypes["Progress"],
	arc3: GraphQLTypes["Progress"],
	arc4: GraphQLTypes["Progress"],
	arc5: GraphQLTypes["Progress"],
	arc6: GraphQLTypes["Progress"],
	arc7: GraphQLTypes["Progress"],
	arc8: GraphQLTypes["Progress"]
};
	["Tracking"]: {
	__typename: "Tracking",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	idQuest: string,
	idMainQuest: string,
	queteAnnexe: Array<boolean>,
	location: GraphQLTypes["Location"],
	users: Array<GraphQLTypes["Users"]>
};
	["Location"]: Location;
	["TrackingProgress"]: {
	__typename: "TrackingProgress",
	arcProgress: GraphQLTypes["ArcProgress"],
	totalProgress: number,
	queteAnnexe: Array<boolean>
};
	["ReputationProgress"]: {
	__typename: "ReputationProgress",
	canopee: number,
	buisnessDistrict: number,
	lagon: number,
	residentialDistrict: number,
	hub: number
};
	["SuccessList"]: {
	__typename: "SuccessList",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	description: string,
	image: string,
	code: string,
	Success: Array<GraphQLTypes["Success"]>
};
	["Success"]: {
	__typename: "Success",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	users: Array<GraphQLTypes["Users"]>,
	successList: GraphQLTypes["SuccessList"],
	successListId: number
};
	["GetAllSuccess"]: {
	__typename: "GetAllSuccess",
	name: string,
	description: string,
	image: string,
	code: string,
	count?: number | undefined,
	unlock?: GraphQLTypes["DateTime"] | undefined
};
	["Users"]: {
	__typename: "Users",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	email: string,
	password?: string | undefined,
	refreshToken?: string | undefined,
	firstName: string,
	lastName: string,
	azureAdOpenId?: string | undefined,
	role: GraphQLTypes["Roles"],
	roleId: number,
	avatar: GraphQLTypes["Avatar"],
	avatarId: number,
	tracking: GraphQLTypes["Tracking"],
	trackingId: number,
	successUnlock: Array<GraphQLTypes["Success"]>
};
	["Roles"]: {
	__typename: "Roles",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	name: string,
	permissions: Array<GraphQLTypes["Permissions"]>,
	users: Array<GraphQLTypes["Users"]>
};
	["Configs"]: {
	__typename: "Configs",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	key: string,
	value: string
};
	["Mail"]: {
	__typename: "Mail",
	id: number,
	uuid: string,
	createdAt: GraphQLTypes["DateTime"],
	updatedAt: GraphQLTypes["DateTime"],
	key: string,
	to: string,
	data: string
};
	["Query"]: {
	__typename: "Query",
	getHealthGql: GraphQLTypes["Health"],
	getRole: GraphQLTypes["Roles"],
	getRoles: Array<GraphQLTypes["Roles"]>,
	getPermission: GraphQLTypes["Permissions"],
	getPermissions: Array<GraphQLTypes["Permissions"]>,
	getConfig: GraphQLTypes["Configs"],
	getConfigs: Array<GraphQLTypes["Configs"]>,
	getMail: GraphQLTypes["Mail"],
	getMails: Array<GraphQLTypes["Mail"]>,
	sendMail: boolean,
	login: GraphQLTypes["AuthType"],
	register: GraphQLTypes["AuthType"],
	refresh: GraphQLTypes["AuthType"],
	logout: boolean,
	getUser: GraphQLTypes["Users"],
	getUsers: Array<GraphQLTypes["Users"]>,
	getAvatar: GraphQLTypes["Avatar"],
	getAllSuccessUnlock: Array<GraphQLTypes["Success"]>,
	getAllSuccess: Array<GraphQLTypes["GetAllSuccess"]>,
	getTracking: GraphQLTypes["Tracking"],
	getReputation: GraphQLTypes["ReputationProgress"],
	getArcsProgress: GraphQLTypes["TrackingProgress"],
	getClothingStats: GraphQLTypes["ClothingUnlock"]
};
	["AuthType"]:{
        	__typename:"Tokens" | "CustomError"
        	['...on Tokens']: '__union' & GraphQLTypes["Tokens"];
	['...on CustomError']: '__union' & GraphQLTypes["CustomError"];
};
	["Tokens"]: {
	__typename: "Tokens",
	access_token: string,
	refresh_token: string
};
	["CustomError"]: {
	__typename: "CustomError",
	error: GraphQLTypes["EError"],
	message: string
};
	["EError"]: EError;
	["Mutation"]: {
	__typename: "Mutation",
	updateAvatar: boolean,
	resetAllSuccess: boolean,
	incrementCountSuccess: boolean,
	updateCountSuccess: boolean,
	unLockSuccess: boolean,
	setScore: boolean,
	updateTracking: boolean,
	popup: boolean
}
    }
export const enum ClothingType {
	TOP = "TOP",
	BOTTOM = "BOTTOM",
	HAT = "HAT"
}
export const enum ClothingRarity {
	EPIQUE = "EPIQUE",
	NORMAL = "NORMAL",
	LEGENDAIRE = "LEGENDAIRE",
	RARE = "RARE"
}
export const enum Location {
	CANOPEE = "CANOPEE",
	LAGON = "LAGON",
	HUB = "HUB",
	BUSINESS_DISTRICT = "BUSINESS_DISTRICT",
	RESIDENTIAL_DISTRICT = "RESIDENTIAL_DISTRICT",
	INTRODUCTION = "INTRODUCTION"
}
export const enum EError {
	AUTH = "AUTH"
}

type ZEUS_VARIABLES = {
	["DateTime"]: ValueTypes["DateTime"];
	["ClothingType"]: ValueTypes["ClothingType"];
	["ClothingRarity"]: ValueTypes["ClothingRarity"];
	["Location"]: ValueTypes["Location"];
	["EError"]: ValueTypes["EError"];
}