import { Selector, InputType, GraphQLTypes } from '@zeus/index';
import { typedGql } from '@zeus/typedDocumentNode';

export const GQL_CLOTHING_STATS = ({ email }: { email: string }) =>
  typedGql('query')({
    getClothingStats: [
      {
        email: email,
      },
      clothingSelector,
    ],
  });

const clothingSelector = Selector('ClothingUnlock')({
  total: true,
  epic: true,
  legendary: true,
  rare: true,
  normal: true,
});

export type StoredClothing = InputType<
  GraphQLTypes['ClothingUnlock'],
  typeof clothingSelector
>;
