import styled from 'styled-components';

import bg from '@assets/image/background/bg-title.png';

type IProps = {
  text: string;
  textcolor?: 'primary' | 'secondary';
  width?: string;
  withbackground?: boolean;
  fullBg?: string;
};

function Title({ textcolor, width, withbackground, fullBg, text }: IProps) {
  return (
    <Container
      $textcolor={textcolor}
      width={width}
      $withbackground={withbackground?.toString()}
      fullBg={fullBg?.toString()}
    >
      <h2>{text}</h2>
    </Container>
  );
}

export default Title;
const Container = styled.div<{
  $textcolor?: 'primary' | 'secondary';
  width?: string;
  $withbackground?: string;
  fullBg?: string;
}>`
  align-items: center;
  background: ${({ $withbackground, fullBg }) => {
    if ($withbackground) {
      if (fullBg) return `url(${bg}) center / 100% 100% no-repeat `;
      else return `url(${bg}) center / contain no-repeat `;
    }
    return 'transparent';
  }};
  color: ${({ $textcolor, theme }) =>
    $textcolor ? theme.palette[$textcolor]?.main : '#ffffff'};
  display: flex;
  flex-direction: row;
  font-size: 1rem;
  font-family: 'Bangers', sans-serif;
  justify-content: center;
  min-width: 450px;
  padding: 10px 70px 15px 70px;
  width: ${({ width }) => (width ? width : 'auto')};

  @media screen and (max-width: 670px) {
    min-width: 100%;
    padding: 10px 50px 15px 50px;
    text-align: center;
  }
`;
