import { styled } from 'styled-components';
import { Drawer } from 'antd';
import { Trans } from '@lingui/react';
import ButtonLink from '~/utils/components/ui/ButtonLink';
import { useAuth } from '~/helpers/store/auth';
import { isAndroid, isIOS, isMobile } from 'react-device-detect';
import { useNavigate } from 'react-router-dom';

type IProps = {
  open: boolean;
  closeDrawer: () => void;
};

function Banner({ open, closeDrawer }: IProps) {
  const { payload } = useAuth();
  const navigate = useNavigate();

  return (
    <SDrawer
      placement="right"
      size="large"
      closeIcon={false}
      bodyStyle={{ background: '#0f030ce6' }}
      onClose={closeDrawer}
      open={open}
    >
      <SDivClose>
        <ButtonLink onClick={closeDrawer}>
          <Trans id="common.close" />
        </ButtonLink>
      </SDivClose>
      <SDivMenuContent>
        {isAndroid && (
          <a href="unitydl://redirect" target="_blank">
            <ButtonLink textcolor="primary">
              <Trans id="common.play" />
            </ButtonLink>
          </a>
        )}
        {isIOS && (
          <a href="unitydl://redirect" target="_blank">
            <ButtonLink textcolor="primary">
              <Trans id="common.play" />
            </ButtonLink>
          </a>
        )}
        {!isMobile && !isAndroid && !isIOS && (
          <a href="/game/index.html" target="_blank">
            <ButtonLink textcolor="primary">
              <Trans id="common.play" />
            </ButtonLink>
          </a>
        )}
        {!payload ? (
          <ButtonLink onClick={() => navigate('/login')}>
            <Trans id="log_in" />
          </ButtonLink>
        ) : (
          <ButtonLink onClick={() => navigate('/dashboard')}>
            Dashboard
          </ButtonLink>
        )}
        <a href="#about">
          <ButtonLink onClick={closeDrawer}>
            <Trans id="common.about" />
          </ButtonLink>
        </a>
        <a href="#gallery">
          <ButtonLink onClick={closeDrawer}>
            <Trans id="common.gallery" />
          </ButtonLink>
        </a>
      </SDivMenuContent>
    </SDrawer>
  );
}

export default Banner;

const SDivClose = styled.div`
  display: flex;
  justify-content: end;
`;

const SDivMenuContent = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  min-width: 300px;
  width: 100%;
`;

const SDrawer = styled(Drawer)`
  &&&&&&&&&&& {
    background: none;
  }
`;
