import { create } from 'zustand';
import { persist } from 'zustand/middleware';

type LocaleState = {
  locale: string;
  setLocale: (locale: string) => void;
};

const useLanguageStore = create<LocaleState>()(
  persist(
    (set) => ({
      locale: 'fr', // default locale
      setLocale: (locale: string) => set({ locale }),
    }),
    {
      name: 'locale-storage',
    }
  )
);

export default useLanguageStore;
