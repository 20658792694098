import styled from 'styled-components';
import UserInfo from './components/UserInfo';
import Footer from '~/utils/components/Footer';
import { useAuth } from '~/helpers/store/auth';
import Progression from './components/Progression';
import Quests from './components/Quests';
import Reputation from './components/Reputation';
import Success from './components/Success';
import { useState } from 'react';
import { useQuery } from '@apollo/client';
import { GQL_AVATAR, StoredAvatar } from '~/gql/avatar/avatar';
import { GQL_SUCCESS, StoredSuccess } from '~/gql/success/success';
import { GQL_REPUTATION_PROGRESS } from '~/gql/tracking/tracking';
import {
  GQL_ARC_QUESTS,
  StoredReputation,
  StoredTrackingProgress,
} from '~/gql/tracking/tracking';
import { GQL_CLOTHING_STATS, StoredClothing } from '~/gql/clothing/clothing';

function Dashboard() {
  const { payload } = useAuth();
  const [avatar, setAvatar] = useState<StoredAvatar | null>(null);
  const [rewards, setRewards] = useState<StoredClothing | null>(null);
  const [success, setSuccess] = useState<StoredSuccess[]>([]);
  const [reputation, setReputation] = useState<StoredReputation | null>(null);
  const [totalProgress, setTotalProgress] = useState<number | null>(null);
  const [arcProgress, setArcProgress] = useState<StoredTrackingProgress | null>(
    null
  );
  const [quests, setQuests] = useState<boolean[]>([]);

  useQuery(GQL_AVATAR({ email: payload?.email ?? '' }), {
    onCompleted: (data) => {
      setAvatar(data.getAvatar);
    },
    onError: (error) => {
      console.log('erreur avatar', error);
    },
  });

  useQuery(GQL_CLOTHING_STATS({ email: payload?.email ?? '' }), {
    onCompleted: (data) => {
      setRewards(data.getClothingStats);
    },
    onError: (error) => {
      console.log('erreur rewards', error);
    },
  });

  useQuery(GQL_SUCCESS({ email: payload?.email ?? '' }), {
    onCompleted: (data) => {
      setSuccess(data.getAllSuccess);
    },
    onError: (error) => {
      console.log('erreur success', error);
    },
  });

  useQuery(GQL_ARC_QUESTS({ email: payload?.email ?? '' }), {
    onCompleted: (data) => {
      setArcProgress(data.getArcsProgress);
      setQuests(data.getArcsProgress.queteAnnexe);
      setTotalProgress(data.getArcsProgress.totalProgress);
    },
    onError: (error) => {
      console.log('erreur arc', error);
    },
  });

  useQuery(GQL_REPUTATION_PROGRESS({ email: payload?.email ?? '' }), {
    onCompleted: (data) => {
      setReputation(data.getReputation);
    },
    onError: (error) => {
      console.log('erreur reputation', error);
    },
  });

  if (!payload) return null;

  return (
    <Container>
      <UserInfo
        userId={payload.access_token}
        avatar={avatar}
        globalProgression={totalProgress}
        rewards={rewards}
      />
      <Success success={success} />
      <Progression arcProgression={arcProgress?.arcProgress} />
      <Quests questsBool={quests} />
      <Reputation reputation={reputation} />
      <SDivFooter>
        <Footer />
      </SDivFooter>
    </Container>
  );
}

export default Dashboard;

const Container = styled.div`
  background: #0f030c;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  perspective: 1px;
  width: 100%;
`;

const SDivFooter = styled.div`
  position: relative;
  transform-style: preserve-3d;
  padding-top: 80px;
  z-index: 4;
`;
