import styled from 'styled-components';
import { Trans } from '@lingui/react';

import bgItem from '@assets/image/background/bg-success-item.png';
import bgItemLocked from '@assets/image/background/bg-success-locked.png';
import iconLock from '@assets/image/icons/icon-lock.png';
import { ISuccess } from '~/pages/dashboard/types';
import { classic } from '~/themes';

type IProps = {
  item: ISuccess;
};

function SuccessItem({ item }: IProps) {
  return (
    <Container $unlocked={item.unlock ? 'false' : 'true'}>
      <SDivImgWrapper>
        <SImg>
          <img alt={item.name} src={item.image} />
        </SImg>
        {!item.unlock && (
          <SImgUnlocked>
            <img alt={item.name} src={iconLock} />
          </SImgUnlocked>
        )}
      </SDivImgWrapper>

      <SDivText>
        <STitle $unlocked={item.unlock ? 'false' : 'true'}>{item.name}</STitle>
        <SPDescription $unlocked={item.unlock ? 'false' : 'true'}>
          {item.description}
        </SPDescription>
      </SDivText>

      <SPCreated>
        {item.unlock && (
          <span>
            <Trans id="common.obtained" /> {item.unlock}
          </span>
        )}
      </SPCreated>

      {!item.unlock && <SDivLocked></SDivLocked>}
    </Container>
  );
}

export default SuccessItem;

const Container = styled.div<{ $unlocked?: string }>`
  align-items: center;
  background: ${({ $unlocked }) =>
    $unlocked === 'false'
      ? `url(${bgItem}) center/100% 100% no-repeat`
      : `url(${bgItemLocked}) center/100% 100%  no-repeat`};
  display: flex;
  justify-content: flex-start;
  height: 100%;
  margin-right: 25px;
  position: relative;
  min-width: 250px;
  width: 100%;
`;
const SDivImgWrapper = styled.div`
  flex: 0 0 16%;
  padding: 8px;
  position: relative;
`;

const SImg = styled.div<{ $unlocked?: string }>`
  background-color: #ffff;
  border-radius: 6px;
  min-height: 60px;
  position: relative;
  min-width: 60px;
  img {
    left: 0;
    height: 100%;
    position: absolute;
    object-fit: contain;
    top: 0;
    width: 100%;
  }
`;
const SDivText = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 0 1 55%;
  flex-direction: column;
  gap: 3px;
  justify-content: center;
  overflow: hidden;
`;
const STitle = styled.h2<{ $unlocked?: string }>`
  color: ${({ $unlocked }) =>
    $unlocked
      ? classic.token?.colorPrimary
      : `${classic.token?.colorTextSecondary}80`}!important;
  font-size: 0.85rem;
  text-align: left;
  width: 100%;
`;
const SPDescription = styled.p<{ $unlocked?: string }>`
  color: ${({ $unlocked }) =>
    $unlocked
      ? classic.token?.colorTextSecondary
      : `${classic.token?.colorTextSecondary}80`}!important;
  font-family: 'MaliRegular', sans-serif;
  font-size: 0.7rem;
  line-height: 1;
`;
const SPCreated = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  justify-self: end;
  flex: 0 0 20%;
  padding-right: 10px;
  & > span {
    color: #ffffff99;
    font-family: 'MaliRegular', sans-serif;
    font-size: 0.7rem;
  }
`;
const SDivLocked = styled.div`
  align-items: center;
  display: flex;
  position: absolute;
  left: 0;
  height: 100%;
  top: 0;
  width: 100%;
`;
const SImgUnlocked = styled.div`
  border-radius: 6px;
  background: url(${bgItemLocked}) center/100% 100% no-repeat;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 7;
  img {
    left: 0;
    height: 100%;
    object-fit: contain;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;
