import { styled } from 'styled-components';
import AboutBackground from '@assets/image/background/bg-image-2.png';
import AboutTextBackground from '@assets/image/background/bg-about.png';
import paralaxTop from '@assets/image/background/paralax-top-1.png';

function About() {
  return (
    <AboutContainer id="about">
      <img src={paralaxTop} alt="paralax-top" width="100%" />
      <div>
        <h1>À propos du jeu Horizons</h1>
        <p>
          Une météorite s’est écrasée dans la paisible ville d’Horizons ! Depuis
          ce jour, des phénomènes inexpliqués se produisent dans la ville et ses
          alentours.
        </p>
        <p>
          En tant que stagiaire de l’E2C, vous répondez à la mobilisation
          citoyenne pour aider les habitants et professionnels dans leur
          quotidien. À travers des mises en situation, mobilisez vos compétences
          et découvrez les métiers du bâtiment, de la restauration, de
          l’industrie, du numérique, de la vente, du service et bien d’autres…
        </p>
        <p>
          Aidez Iris, Mila et Lukas à résoudre les mystères de la ville et
          affrontez en « battle » les autres joueurs sur la place du
          centre-ville pour obtenir des objets de personnalisation uniques !
        </p>
        <p>
          Horizons, est un jeu pédagogique se basant sur la démarche d’Approche
          Par Compétences du Réseau E2C France. Il s’agit d’un processus qui
          permet de comprendre mais aussi d’acquérir des compétences en
          réalisant des mises en situation.
        </p>
        <p>
          Vous avez entre 16 et 25 ans, vous n’avez pas de diplôme, vous
          cherchez votre voie ?
        </p>
        <p>
          À l'E2C, nous vous proposons une formation gratuite et rémunérée, avec
          l’opportunité de découvrir des métiers, de faire des stages et de
          l’alternance. Pendant votre parcours, vous pourrez acquérir des
          compétences tout en bénéficiant d’un accompagnement individualisé.
        </p>
        <p>
          Il y a forcément une E2C proche de chez vous ! Cliquez sur le lien
          suivant pour la contacter :{' '}
          <a href="https://reseau-e2c.fr/cartographie-des-e2c" target="_blank">
            ici
          </a>
        </p>
      </div>
      <img src={paralaxTop} alt="paralax-bottom-return" />
    </AboutContainer>
  );
}

export default About;

const AboutContainer = styled.div`
  position: relative;
  transform-style: preserve-3d;
  -webkit-box-align: center;
  align-items: center;
  background: url(${AboutBackground}) center top / cover no-repeat fixed;
  display: flex;
  flex-direction: column;
  -webkit-box-pack: justify;
  justify-content: space-between;
  z-index: 1;

  & img:nth-child(1) {
    height: 20%;
    margin-top: -1px;
    width: 100%;
  }

  & img:nth-child(3) {
    height: 20%;
    width: 100%;
    transform: rotate(180deg);
  }

  & > div {
    background: url(${AboutTextBackground}) center top / 100% 100% no-repeat;
    color: rgb(255, 255, 255);
    margin: 90px;
    padding: 40px 40px 50px;
    place-self: center;
    width: 60%;

    & h1 {
      color: rgb(231, 93, 93);
      margin-bottom: 30px;
      padding-top: 10px;
      text-align: center;
      width: 100%;
    }

    & p {
      color: rgb(39, 12, 33);
      font-size: 1.2rem;
      margin-bottom: 20px;

      & > a {
        color: rgb(231, 93, 93);
      }
      @media screen and (max-width: 415px) {
        font-size: 0.9rem;
      }
      @media screen and (max-width: 667px) {
        margin-bottom: 10px;
      }
    }
    @media screen and (max-width: 500px) {
      width: 95%;
    }

    @media screen and (max-width: 767px) {
      margin: 20px;
    }
  }
`;
